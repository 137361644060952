import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock, createVNode as _createVNode, renderList as _renderList, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveDynamicComponent as _resolveDynamicComponent, createSlots as _createSlots } from "vue"

const _hoisted_1 = { class: "bp-customer-editor-product-bestinformed flex-column gap-md" }
const _hoisted_2 = { class: "flex-column gap-md" }
const _hoisted_3 = { class: "bp-customer-editor-product-bestinformed__meta-grid" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_translate = _resolveComponent("translate")!
  const _component_bp_select = _resolveComponent("bp-select")!
  const _component_bp_input = _resolveComponent("bp-input")!
  const _component_bp_icon = _resolveComponent("bp-icon")!
  const _component_bp_label = _resolveComponent("bp-label")!
  const _component_bp_fieldset = _resolveComponent("bp-fieldset")!
  const _component_bp_tooltip = _resolveComponent("bp-tooltip")!
  const _component_bp_button = _resolveComponent("bp-button")!
  const _component_bp_spacer = _resolveComponent("bp-spacer")!
  const _component_bp_contact_editor = _resolveComponent("bp-contact-editor")!
  const _component_bp_form_button = _resolveComponent("bp-form-button")!
  const _component_bp_window = _resolveComponent("bp-window")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      (_ctx.show('editionProduct'))
        ? (_openBlock(), _createBlock(_component_translate, {
            key: 0,
            tag: `h${_ctx.headingLevel}`
          }, {
            default: _withCtx(() => _cache[55] || (_cache[55] = [
              _createTextVNode("License")
            ])),
            _: 1
          }, 8, ["tag"]))
        : _createCommentVNode("", true),
      (_ctx.show('editionProduct'))
        ? (_openBlock(), _createBlock(_component_bp_select, {
            key: 1,
            data: _ctx.productVersions,
            disabled: !_ctx.selected,
            indent: 1.25,
            label: _ctx.$gettext('Version'),
            "search-property": `name.${_ctx.currentLanguageISOString()}`,
            "sort-order": "desc",
            modelValue: _ctx.productVersion,
            "onUpdate:modelValue": [
              _cache[0] || (_cache[0] = ($event: any) => ((_ctx.productVersion) = $event)),
              _ctx.updateVersionErrors
            ]
          }, null, 8, ["data", "disabled", "label", "search-property", "modelValue", "onUpdate:modelValue"]))
        : _createCommentVNode("", true),
      (_ctx.customer.editionProduct !== 'Apps')
        ? (_openBlock(), _createElementBlock(_Fragment, { key: 2 }, [
            (_ctx.show('editionProduct') && _ctx.editionStore.loaded)
              ? (_openBlock(), _createBlock(_component_bp_select, {
                  key: 0,
                  data: _ctx.editionStore.editions.bestinformed5.filter(edition => !edition._id.startsWith('Infoband')),
                  disabled: !_ctx.selected || _ctx.vtigerDisabled('editionProduct'),
                  indent: 1.25,
                  label: _ctx.$gettext('Edition'),
                  required: _ctx.customer.editionProduct !== 'Apps',
                  onChangeValid: _cache[1] || (_cache[1] = ($event: any) => (_ctx.setError('editionProduct', _ctx.$gettext('Edition'), $event))),
                  "search-property": "name",
                  modelValue: _ctx.customer.editionProduct,
                  "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.customer.editionProduct) = $event))
                }, null, 8, ["data", "disabled", "label", "required", "modelValue"]))
              : _createCommentVNode("", true),
            (_ctx.show(['numberOfLicences', 'unlimited_cals']))
              ? (_openBlock(), _createBlock(_component_bp_label, {
                  key: 1,
                  disabled: !_ctx.selected || _ctx.vtigerDisabled('numberOfLicences'),
                  indent: 1.25,
                  "inner-gap": 0,
                  label: _ctx.$gettext('Amount of CALs')
                }, {
                  default: _withCtx(() => [
                    (_ctx.show('numberOfLicences'))
                      ? (_openBlock(), _createBlock(_component_bp_input, {
                          key: 0,
                          disabled: !_ctx.selected || _ctx.customer.unlimited_cals || _ctx.vtigerDisabled('numberOfLicences'),
                          min: 1,
                          onChangeValid: _cache[3] || (_cache[3] = ($event: any) => (_ctx.setError('numberOfLicences', _ctx.$gettext('Amount of CALs'), $event))),
                          type: "number",
                          modelValue: _ctx.customer.numberOfLicences,
                          "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.customer.numberOfLicences) = $event))
                        }, null, 8, ["disabled", "modelValue"]))
                      : _createCommentVNode("", true),
                    (_ctx.show('numberOfLicences') && _ctx.show('unlimited_cals'))
                      ? (_openBlock(), _createBlock(_component_bp_icon, {
                          key: 1,
                          color: !_ctx.selected ? 'var(--theme-text-disabled)' : undefined,
                          class: "flex-0 p-md pr-0",
                          icon: "slash-forward"
                        }, null, 8, ["color"]))
                      : _createCommentVNode("", true),
                    (_ctx.show('unlimited_cals'))
                      ? (_openBlock(), _createBlock(_component_bp_input, {
                          key: 2,
                          disabled: !_ctx.selected || _ctx.vtigerDisabled('unlimited_cals'),
                          text: _ctx.$gettext('Unlimited CALs'),
                          onChangeValid: _cache[5] || (_cache[5] = ($event: any) => (_ctx.setError('unlimited_cals', _ctx.$gettext('Unlimited CALs'), $event))),
                          class: "flex-0 ws-nw",
                          type: "checkbox",
                          modelValue: _ctx.customer.unlimited_cals,
                          "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.customer.unlimited_cals) = $event))
                        }, null, 8, ["disabled", "text", "modelValue"]))
                      : _createCommentVNode("", true)
                  ]),
                  _: 1
                }, 8, ["disabled", "label"]))
              : _createCommentVNode("", true)
          ], 64))
        : (_openBlock(), _createElementBlock(_Fragment, { key: 3 }, [
            (_ctx.show('editionApps') && _ctx.editionStore.loaded)
              ? (_openBlock(), _createBlock(_component_bp_select, {
                  key: 0,
                  data: _ctx.editionStore.editions.bestinformed6,
                  disabled: !_ctx.selected || _ctx.vtigerDisabled('editionApps'),
                  indent: 1.25,
                  label: _ctx.$gettext('Edition'),
                  "model-value": _ctx.customer.editionApps,
                  required: _ctx.customer.editionProduct === 'Apps',
                  onChangeValid: _cache[7] || (_cache[7] = ($event: any) => (_ctx.setError('editionApps', _ctx.$gettext('Edition'), $event))),
                  "onUpdate:modelValue": _ctx.updateEditionApps,
                  "search-property": "name"
                }, null, 8, ["data", "disabled", "label", "model-value", "required", "onUpdate:modelValue"]))
              : _createCommentVNode("", true),
            (_ctx.show(['apps', 'apps_full']))
              ? (_openBlock(), _createBlock(_component_bp_fieldset, {
                  key: 1,
                  legend: _ctx.$gettext('Apps'),
                  "intense-border": ""
                }, {
                  default: _withCtx(() => [
                    (_ctx.customer.editionApps)
                      ? (_openBlock(), _createBlock(_component_bp_select, {
                          key: 0,
                          data: _ctx.availableApps(_ctx.AppType.APP_PLUS),
                          disabled: !_ctx.selected || _ctx.vtigerDisabled('appPlus'),
                          indent: 2.375,
                          label: _ctx.$gettext('App Plus'),
                          "model-value": _ctx.selectedApps(_ctx.AppType.APP_PLUS),
                          onChangeValid: _cache[8] || (_cache[8] = ($event: any) => (_ctx.setError('apps', _ctx.$gettext('App Plus'), $event))),
                          "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => (_ctx.updateApps(_ctx.AppType.APP_PLUS, $event))),
                          clearable: "",
                          "hide-selected": "",
                          multiple: "",
                          "search-property": "name"
                        }, null, 8, ["data", "disabled", "label", "model-value"]))
                      : _createCommentVNode("", true),
                    (_ctx.customer.editionApps)
                      ? (_openBlock(), _createBlock(_component_bp_select, {
                          key: 1,
                          data: _ctx.availableApps(_ctx.AppType.ADDITIONAL_APPS),
                          disabled: !_ctx.selected || _ctx.vtigerDisabled('additionalApps'),
                          indent: 2.375,
                          label: _ctx.$gettext('Additional apps'),
                          "model-value": _ctx.selectedApps(_ctx.AppType.ADDITIONAL_APPS),
                          onChangeValid: _cache[10] || (_cache[10] = ($event: any) => (_ctx.setError('apps', _ctx.$gettext('Additional apps'), $event))),
                          "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => (_ctx.updateApps(_ctx.AppType.ADDITIONAL_APPS, $event))),
                          clearable: "",
                          "hide-selected": "",
                          multiple: "",
                          "search-property": "name"
                        }, null, 8, ["data", "disabled", "label", "model-value"]))
                      : (_openBlock(), _createBlock(_component_translate, {
                          key: 2,
                          tag: "em"
                        }, {
                          default: _withCtx(() => _cache[56] || (_cache[56] = [
                            _createTextVNode("Please select an edition…")
                          ])),
                          _: 1
                        }))
                  ]),
                  _: 1
                }, 8, ["legend"]))
              : _createCommentVNode("", true),
            (_ctx.show(['numberOfLicences', 'unlimited_cals', 'mobilecals', 'clusternodes']))
              ? (_openBlock(), _createBlock(_component_bp_fieldset, {
                  key: 2,
                  legend: _ctx.$gettext('Licenses'),
                  "intense-border": ""
                }, {
                  default: _withCtx(() => [
                    (_ctx.show(['numberOfLicences', 'unlimited_cals']))
                      ? (_openBlock(), _createBlock(_component_bp_label, {
                          key: 0,
                          disabled: !_ctx.selected || _ctx.vtigerDisabled('numberOfLicences'),
                          indent: 2.375,
                          "inner-gap": 0,
                          label: _ctx.$gettext('Amount of CALs')
                        }, {
                          default: _withCtx(() => [
                            (_ctx.show('numberOfLicences'))
                              ? (_openBlock(), _createBlock(_component_bp_input, {
                                  key: 0,
                                  disabled: !_ctx.selected || _ctx.customer.unlimited_cals || _ctx.vtigerDisabled('numberOfLicences'),
                                  min: 1,
                                  onChangeValid: _cache[12] || (_cache[12] = ($event: any) => (_ctx.setError('numberOfLicences', _ctx.$gettext('Amount of CALs'), $event))),
                                  type: "number",
                                  modelValue: _ctx.customer.numberOfLicences,
                                  "onUpdate:modelValue": _cache[13] || (_cache[13] = ($event: any) => ((_ctx.customer.numberOfLicences) = $event))
                                }, null, 8, ["disabled", "modelValue"]))
                              : _createCommentVNode("", true),
                            (_ctx.show('numberOfLicences') && _ctx.show('unlimited_cals'))
                              ? (_openBlock(), _createBlock(_component_bp_icon, {
                                  key: 1,
                                  color: !_ctx.selected ? 'var(--theme-text-disabled)' : undefined,
                                  class: "flex-0 p-md pr-0",
                                  icon: "slash-forward"
                                }, null, 8, ["color"]))
                              : _createCommentVNode("", true),
                            (_ctx.show('unlimited_cals'))
                              ? (_openBlock(), _createBlock(_component_bp_input, {
                                  key: 2,
                                  disabled: !_ctx.selected || _ctx.vtigerDisabled('unlimited_cals'),
                                  text: _ctx.$gettext('Unlimited CALs'),
                                  onChangeValid: _cache[14] || (_cache[14] = ($event: any) => (_ctx.setError('unlimited_cals', _ctx.$gettext('Unlimited CALs'), $event))),
                                  class: "flex-0 ws-nw",
                                  type: "checkbox",
                                  modelValue: _ctx.customer.unlimited_cals,
                                  "onUpdate:modelValue": _cache[15] || (_cache[15] = ($event: any) => ((_ctx.customer.unlimited_cals) = $event))
                                }, null, 8, ["disabled", "text", "modelValue"]))
                              : _createCommentVNode("", true)
                          ]),
                          _: 1
                        }, 8, ["disabled", "label"]))
                      : _createCommentVNode("", true),
                    (_ctx.show('mobilecals') && _ctx.appStore.loaded)
                      ? (_openBlock(), _createBlock(_component_bp_tooltip, {
                          key: 1,
                          condition: !_ctx.selected || !_ctx.customer.apps.includes('mobile'),
                          tooltip: _ctx.$gettext('Additional app "%{app}" is required', { app: _ctx.appStore.getById('mobile').name || '' })
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_bp_input, {
                              disabled: !_ctx.selected || !_ctx.customer.apps.includes('mobile') || _ctx.vtigerDisabled('mobileCals'),
                              indent: 2.375,
                              label: _ctx.$gettext('Amount of mobile CALs'),
                              onChangeValid: _cache[16] || (_cache[16] = ($event: any) => (_ctx.setError('mobilecals', _ctx.$gettext('Amount of mobile CALs'), $event))),
                              type: "number",
                              modelValue: _ctx.customer.mobilecals,
                              "onUpdate:modelValue": _cache[17] || (_cache[17] = ($event: any) => ((_ctx.customer.mobilecals) = $event))
                            }, null, 8, ["disabled", "label", "modelValue"])
                          ]),
                          _: 1
                        }, 8, ["condition", "tooltip"]))
                      : _createCommentVNode("", true),
                    (_ctx.show('clusternodes'))
                      ? (_openBlock(), _createBlock(_component_bp_input, {
                          key: 2,
                          disabled: !_ctx.selected || _ctx.vtigerDisabled('clusternodes'),
                          indent: 2.375,
                          label: _ctx.$gettext('Amount of cluster nodes'),
                          onChangeValid: _cache[18] || (_cache[18] = ($event: any) => (_ctx.setError('clusternodes', _ctx.$gettext('Amount of cluster nodes'), $event))),
                          type: "number",
                          modelValue: _ctx.customer.clusternodes,
                          "onUpdate:modelValue": _cache[19] || (_cache[19] = ($event: any) => ((_ctx.customer.clusternodes) = $event))
                        }, null, 8, ["disabled", "label", "modelValue"]))
                      : _createCommentVNode("", true)
                  ]),
                  _: 1
                }, 8, ["legend"]))
              : _createCommentVNode("", true),
            (_ctx.show(['hosting', 'subscription']))
              ? (_openBlock(), _createBlock(_component_translate, {
                  key: 3,
                  tag: `h${_ctx.headingLevel + 1}`
                }, {
                  default: _withCtx(() => _cache[57] || (_cache[57] = [
                    _createTextVNode("Services")
                  ])),
                  _: 1
                }, 8, ["tag"]))
              : _createCommentVNode("", true),
            (_ctx.show('hosting'))
              ? (_openBlock(), _createBlock(_component_bp_fieldset, {
                  key: 4,
                  collapsed: !_ctx.getHosting('bestinformed'),
                  "intense-border": ""
                }, {
                  legend: _withCtx(() => [
                    (_ctx.show('hosting'))
                      ? (_openBlock(), _createBlock(_component_bp_input, {
                          key: 0,
                          disabled: !_ctx.selected,
                          "model-value": _ctx.getHosting('bestinformed'),
                          text: _ctx.$gettext('Hosting'),
                          onChangeValid: _cache[20] || (_cache[20] = ($event: any) => (_ctx.setError('hosting', _ctx.$gettext('Hosting'), $event))),
                          "onUpdate:modelValue": _cache[21] || (_cache[21] = ($event: any) => (_ctx.updateHosting('bestinformed', $event))),
                          flush: "",
                          type: "checkbox"
                        }, null, 8, ["disabled", "model-value", "text"]))
                      : _createCommentVNode("", true)
                  ]),
                  default: _withCtx(() => [
                    (_ctx.show('hosting') && _ctx.show('hostingruntimeto'))
                      ? (_openBlock(), _createBlock(_component_bp_input, {
                          key: 0,
                          disabled: !_ctx.selected,
                          indent: 2.375,
                          label: _ctx.$gettext('Runtime to'),
                          required: _ctx.getHosting('bestinformed'),
                          text: _ctx.customerStore.hasProduct(_ctx.customer, 'bestproxy') && _ctx.getHosting('bestproxy') ? _ctx.$gettext('The runtime is linked to the hosting runtime of %{product}', { product: 'bestproxy' }) : undefined,
                          onChangeValid: _cache[22] || (_cache[22] = ($event: any) => (_ctx.setError('hostingruntimeto', _ctx.$gettext('Runtime to'), $event))),
                          type: "date",
                          modelValue: _ctx.customer.hostingruntimeto,
                          "onUpdate:modelValue": _cache[23] || (_cache[23] = ($event: any) => ((_ctx.customer.hostingruntimeto) = $event))
                        }, null, 8, ["disabled", "label", "required", "text", "modelValue"]))
                      : _createCommentVNode("", true),
                    (_ctx.show('hosting') && _ctx.show('hostingCancel') && !!_ctx.modelValue?._id)
                      ? (_openBlock(), _createBlock(_component_bp_fieldset, {
                          key: 1,
                          collapsed: !_ctx.customer.hostingCancel,
                          "intense-border": ""
                        }, {
                          legend: _withCtx(() => [
                            (_ctx.show('hosting') && _ctx.show('hostingCancel'))
                              ? (_openBlock(), _createBlock(_component_bp_input, {
                                  key: 0,
                                  disabled: !_ctx.selected,
                                  indent: 3.5,
                                  text: _ctx.$gettext('The customer has canceled hosting'),
                                  onChangeValid: _cache[24] || (_cache[24] = ($event: any) => (_ctx.setError('hostingCancel', _ctx.$gettext('Hosting canceled'), $event))),
                                  flush: "",
                                  type: "checkbox",
                                  "model-value": _ctx.customer.hostingCancel,
                                  "onUpdate:modelValue": _ctx.updateHostingCancel
                                }, null, 8, ["disabled", "text", "model-value", "onUpdate:modelValue"]))
                              : _createCommentVNode("", true)
                          ]),
                          default: _withCtx(() => [
                            (_ctx.show('hosting') && _ctx.show('hostingCancel') && _ctx.show('hosting_canceled_name'))
                              ? (_openBlock(), _createBlock(_component_bp_label, {
                                  key: 0,
                                  indent: 3.5,
                                  label: _ctx.$gettext('Canceled from'),
                                  required: _ctx.customer.hostingCancel
                                }, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_bp_select, {
                                      data: _ctx.contactStore.getByCustomerId(_ctx.customer._id || ''),
                                      disabled: !_ctx.selected,
                                      required: _ctx.customer.hostingCancel,
                                      transform: _ctx.transformContact,
                                      onChangeValid: _cache[25] || (_cache[25] = ($event: any) => (_ctx.setError('hosting_canceled_name', _ctx.$gettext('Canceled from'), $event))),
                                      modelValue: _ctx.customer.hosting_canceled_name,
                                      "onUpdate:modelValue": _cache[26] || (_cache[26] = ($event: any) => ((_ctx.customer.hosting_canceled_name) = $event))
                                    }, null, 8, ["data", "disabled", "required", "transform", "modelValue"]),
                                    _createVNode(_component_bp_button, {
                                      class: "flex-0",
                                      icon: "plus",
                                      color: "green",
                                      onClick: _cache[27] || (_cache[27] = ($event: any) => (_ctx.contactWindow = 'hosting')),
                                      large: ""
                                    })
                                  ]),
                                  _: 1
                                }, 8, ["label", "required"]))
                              : _createCommentVNode("", true),
                            (_ctx.show('hosting') && _ctx.show('hostingCancel') && _ctx.show('hosting_canceled_date'))
                              ? (_openBlock(), _createBlock(_component_bp_input, {
                                  key: 1,
                                  disabled: !_ctx.selected,
                                  indent: 3.5,
                                  label: _ctx.$gettext('Canceled date'),
                                  required: _ctx.customer.hostingCancel,
                                  onChangeValid: _cache[28] || (_cache[28] = ($event: any) => (_ctx.setError('hosting_canceled_date', _ctx.$gettext('Canceled date'), $event))),
                                  type: "date",
                                  modelValue: _ctx.customer.hosting_canceled_date,
                                  "onUpdate:modelValue": _cache[29] || (_cache[29] = ($event: any) => ((_ctx.customer.hosting_canceled_date) = $event))
                                }, null, 8, ["disabled", "label", "required", "modelValue"]))
                              : _createCommentVNode("", true),
                            (_ctx.show('hosting') && _ctx.show('hostingCancel') && _ctx.show('hosting_canceled_type'))
                              ? (_openBlock(), _createBlock(_component_bp_select, {
                                  key: 2,
                                  data: _ctx.cancelledTypeStore.cancelledTypes,
                                  disabled: !_ctx.selected,
                                  indent: 3.5,
                                  label: _ctx.$gettext('Canceled type'),
                                  required: _ctx.customer.hostingCancel,
                                  onChangeValid: _cache[30] || (_cache[30] = ($event: any) => (_ctx.setError('hosting_canceled_type', _ctx.$gettext('Canceled type'), $event))),
                                  "search-property": "name",
                                  modelValue: _ctx.customer.hosting_canceled_type,
                                  "onUpdate:modelValue": _cache[31] || (_cache[31] = ($event: any) => ((_ctx.customer.hosting_canceled_type) = $event))
                                }, null, 8, ["data", "disabled", "label", "required", "modelValue"]))
                              : _createCommentVNode("", true),
                            (_ctx.show('hosting') && _ctx.show('hostingCancel') && _ctx.show('hosting_canceledTo'))
                              ? (_openBlock(), _createBlock(_component_bp_input, {
                                  key: 3,
                                  disabled: !_ctx.selected,
                                  indent: 3.5,
                                  label: _ctx.$gettext('Canceled to'),
                                  required: _ctx.customer.hostingCancel,
                                  onChangeValid: _cache[32] || (_cache[32] = ($event: any) => (_ctx.setError('hosting_canceledTo', _ctx.$gettext('Canceled to'), $event))),
                                  type: "date",
                                  modelValue: _ctx.customer.hosting_canceledTo,
                                  "onUpdate:modelValue": _cache[33] || (_cache[33] = ($event: any) => ((_ctx.customer.hosting_canceledTo) = $event))
                                }, null, 8, ["disabled", "label", "required", "modelValue"]))
                              : _createCommentVNode("", true)
                          ]),
                          _: 1
                        }, 8, ["collapsed"]))
                      : _createCommentVNode("", true)
                  ]),
                  _: 1
                }, 8, ["collapsed"]))
              : _createCommentVNode("", true),
            (_ctx.show('subscription'))
              ? (_openBlock(), _createBlock(_component_bp_input, {
                  key: 5,
                  disabled: !_ctx.selected || _ctx.vtigerDisabled('subscription'),
                  indent: 1.25,
                  label: _ctx.$gettext('Subscription'),
                  text: _ctx.$gettext('The customer has a bestinformed subscription'),
                  onChangeValid: _cache[34] || (_cache[34] = ($event: any) => (_ctx.setError('subscription', _ctx.$gettext('Subscription'), $event))),
                  flush: "",
                  type: "checkbox",
                  modelValue: _ctx.customer.subscription,
                  "onUpdate:modelValue": _cache[35] || (_cache[35] = ($event: any) => ((_ctx.customer.subscription) = $event))
                }, null, 8, ["disabled", "label", "text", "modelValue"]))
              : _createCommentVNode("", true)
          ], 64)),
      ((_ctx.show(['supportFrom', 'supportTo']) && _ctx.isLargeDesktop))
        ? (_openBlock(), _createBlock(_component_bp_label, {
            key: 4,
            disabled: !_ctx.selected,
            "inner-gap": 0,
            indent: 1.25,
            label: _ctx.customer.subscription ? _ctx.$gettext('Subscription from') + ' / ' + _ctx.$gettext('Subscription to') : _ctx.$gettext('Support from') + ' / ' + _ctx.$gettext('Support to')
          }, {
            default: _withCtx(() => [
              (_ctx.show('supportFrom'))
                ? (_openBlock(), _createBlock(_component_bp_input, {
                    key: 0,
                    disabled: !_ctx.selected,
                    "max-date": _ctx.customer.supportTo,
                    onChangeValid: _cache[36] || (_cache[36] = ($event: any) => (_ctx.setError('supportFrom', _ctx.customer.subscription ? _ctx.$gettext('Subscription from') : _ctx.$gettext('Support from'), $event))),
                    type: "date",
                    modelValue: _ctx.customer.supportFrom,
                    "onUpdate:modelValue": _cache[37] || (_cache[37] = ($event: any) => ((_ctx.customer.supportFrom) = $event))
                  }, null, 8, ["disabled", "max-date", "modelValue"]))
                : _createCommentVNode("", true),
              (_ctx.show('supportFrom') && _ctx.show('supportTo'))
                ? (_openBlock(), _createBlock(_component_bp_icon, {
                    key: 1,
                    color: !_ctx.selected ? 'var(--theme-text-disabled)' : undefined,
                    class: "flex-0 p-md",
                    icon: "dash"
                  }, null, 8, ["color"]))
                : _createCommentVNode("", true),
              (_ctx.show('supportTo'))
                ? (_openBlock(), _createBlock(_component_bp_input, {
                    key: 2,
                    disabled: !_ctx.selected || _ctx.vtigerDisabled('supportTo'),
                    "min-date": _ctx.customer.supportFrom,
                    onChangeValid: _cache[38] || (_cache[38] = ($event: any) => (_ctx.setError('supportTo', _ctx.customer.subscription ? _ctx.$gettext('Subscription to') : _ctx.$gettext('Support to'), $event))),
                    type: "date",
                    modelValue: _ctx.customer.supportTo,
                    "onUpdate:modelValue": _cache[39] || (_cache[39] = ($event: any) => ((_ctx.customer.supportTo) = $event))
                  }, null, 8, ["disabled", "min-date", "modelValue"]))
                : _createCommentVNode("", true),
              (_openBlock(), _createElementBlock(_Fragment, null, _renderList([1, 3], (years) => {
                return (_openBlock(), _createElementBlock(_Fragment, { key: years }, [
                  (_ctx.show('supportTo'))
                    ? (_openBlock(), _createBlock(_component_bp_spacer, { key: 0 }))
                    : _createCommentVNode("", true),
                  (_ctx.show('supportTo'))
                    ? (_openBlock(), _createBlock(_component_bp_button, {
                        key: 1,
                        icon: ['far', 'calendar-range'],
                        onClick: ($event: any) => (_ctx.updateSupportTo(years)),
                        disabled: _ctx.vtigerDisabled('supportTo'),
                        class: "flex-0 ws-nw",
                        large: ""
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_translate, {
                            "translate-n": years,
                            "translate-params": { years },
                            "translate-plural": "%{years} years"
                          }, {
                            default: _withCtx(() => _cache[58] || (_cache[58] = [
                              _createTextVNode("%{years} year")
                            ])),
                            _: 2
                          }, 1032, ["translate-n", "translate-params"])
                        ]),
                        _: 2
                      }, 1032, ["onClick", "disabled"]))
                    : _createCommentVNode("", true)
                ], 64))
              }), 64))
            ]),
            _: 1
          }, 8, ["disabled", "label"]))
        : (_ctx.show(['supportFrom', 'supportTo']) && !_ctx.isLargeDesktop)
          ? (_openBlock(), _createElementBlock(_Fragment, { key: 5 }, [
              (_ctx.show('supportFrom'))
                ? (_openBlock(), _createBlock(_component_bp_input, {
                    key: 0,
                    disabled: !_ctx.selected,
                    indent: 1.25,
                    "inner-gap": 0,
                    label: _ctx.customer.subscription ? _ctx.$gettext('Subscription from') : _ctx.$gettext('Support from'),
                    "max-date": _ctx.customer.supportTo,
                    onChangeValid: _cache[40] || (_cache[40] = ($event: any) => (_ctx.setError('supportFrom', _ctx.customer.subscription ? _ctx.$gettext('Subscription from') : _ctx.$gettext('Support from'), $event))),
                    type: "date",
                    modelValue: _ctx.customer.supportFrom,
                    "onUpdate:modelValue": _cache[41] || (_cache[41] = ($event: any) => ((_ctx.customer.supportFrom) = $event))
                  }, null, 8, ["disabled", "label", "max-date", "modelValue"]))
                : _createCommentVNode("", true),
              (_ctx.show(['supportTo']))
                ? (_openBlock(), _createBlock(_component_bp_label, {
                    key: 1,
                    disabled: !_ctx.selected || _ctx.vtigerDisabled('supportTo'),
                    indent: 1.25,
                    "inner-gap": 0,
                    label: _ctx.customer.subscription ? _ctx.$gettext('Subscription to') : _ctx.$gettext('Support to')
                  }, {
                    default: _withCtx(() => [
                      _createElementVNode("div", _hoisted_2, [
                        (_ctx.show('supportTo'))
                          ? (_openBlock(), _createBlock(_component_bp_input, {
                              key: 0,
                              disabled: !_ctx.selected || _ctx.vtigerDisabled('supportTo'),
                              "min-date": _ctx.customer.supportFrom,
                              onChangeValid: _cache[42] || (_cache[42] = ($event: any) => (_ctx.setError('supportTo', _ctx.customer.subscription ? _ctx.$gettext('Subscription to') : _ctx.$gettext('Support to'), $event))),
                              type: "date",
                              modelValue: _ctx.customer.supportTo,
                              "onUpdate:modelValue": _cache[43] || (_cache[43] = ($event: any) => ((_ctx.customer.supportTo) = $event))
                            }, null, 8, ["disabled", "min-date", "modelValue"]))
                          : _createCommentVNode("", true),
                        _createElementVNode("div", {
                          class: _normalizeClass([{ 'flex': _ctx.isDesktop, 'flex-column': !_ctx.isDesktop }, 'gap-md'])
                        }, [
                          (_openBlock(), _createElementBlock(_Fragment, null, _renderList([1, 3], (years) => {
                            return (_openBlock(), _createElementBlock(_Fragment, { key: years }, [
                              (_ctx.show('supportTo'))
                                ? (_openBlock(), _createBlock(_component_bp_button, {
                                    key: 0,
                                    icon: ['far', 'calendar-range'],
                                    onClick: ($event: any) => (_ctx.updateSupportTo(years)),
                                    disabled: _ctx.vtigerDisabled('supportTo'),
                                    class: "flex-0 ws-nw"
                                  }, {
                                    default: _withCtx(() => [
                                      _createVNode(_component_translate, {
                                        "translate-n": years,
                                        "translate-params": { years },
                                        "translate-plural": "%{years} years"
                                      }, {
                                        default: _withCtx(() => _cache[59] || (_cache[59] = [
                                          _createTextVNode("%{years} year")
                                        ])),
                                        _: 2
                                      }, 1032, ["translate-n", "translate-params"])
                                    ]),
                                    _: 2
                                  }, 1032, ["onClick", "disabled"]))
                                : _createCommentVNode("", true)
                            ], 64))
                          }), 64))
                        ], 2)
                      ])
                    ]),
                    _: 1
                  }, 8, ["disabled", "label"]))
                : _createCommentVNode("", true)
            ], 64))
          : _createCommentVNode("", true),
      (_ctx.show('supportCancel') && !!_ctx.modelValue?._id)
        ? (_openBlock(), _createBlock(_component_bp_fieldset, {
            key: 6,
            collapsed: !_ctx.customer.supportCancel,
            "intense-border": ""
          }, {
            legend: _withCtx(() => [
              (_ctx.show('supportCancel'))
                ? (_openBlock(), _createBlock(_component_bp_input, {
                    key: 0,
                    disabled: !_ctx.selected,
                    text: _ctx.$gettext('The customer has canceled'),
                    onChangeValid: _cache[44] || (_cache[44] = ($event: any) => (_ctx.setError('supportCancel', _ctx.$gettext('Canceled'), $event))),
                    flush: "",
                    type: "checkbox",
                    "model-value": _ctx.customer.supportCancel,
                    "onUpdate:modelValue": _ctx.updateSupportCancel
                  }, null, 8, ["disabled", "text", "model-value", "onUpdate:modelValue"]))
                : _createCommentVNode("", true)
            ]),
            default: _withCtx(() => [
              (_ctx.show('supportCancel') && _ctx.show('canceled_name'))
                ? (_openBlock(), _createBlock(_component_bp_label, {
                    key: 0,
                    indent: 2.375,
                    label: _ctx.$gettext('Canceled from'),
                    required: _ctx.customer.supportCancel
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_bp_select, {
                        data: _ctx.contactStore.getByCustomerId(_ctx.customer._id || ''),
                        disabled: !_ctx.selected,
                        required: _ctx.customer.supportCancel,
                        transform: _ctx.transformContact,
                        onChangeValid: _cache[45] || (_cache[45] = ($event: any) => (_ctx.setError('canceled_name', _ctx.$gettext('Canceled from'), $event))),
                        modelValue: _ctx.customer.canceled_name,
                        "onUpdate:modelValue": _cache[46] || (_cache[46] = ($event: any) => ((_ctx.customer.canceled_name) = $event))
                      }, null, 8, ["data", "disabled", "required", "transform", "modelValue"]),
                      _createVNode(_component_bp_button, {
                        class: "flex-0",
                        icon: "plus",
                        color: "green",
                        onClick: _cache[47] || (_cache[47] = ($event: any) => {_ctx.contactWindow = 'support';}),
                        large: ""
                      })
                    ]),
                    _: 1
                  }, 8, ["label", "required"]))
                : _createCommentVNode("", true),
              (_ctx.show('supportCancel') && _ctx.show('canceled_date'))
                ? (_openBlock(), _createBlock(_component_bp_input, {
                    key: 1,
                    disabled: !_ctx.selected,
                    indent: 2.375,
                    label: _ctx.$gettext('Canceled date'),
                    required: _ctx.customer.supportCancel,
                    onChangeValid: _cache[48] || (_cache[48] = ($event: any) => (_ctx.setError('canceled_date', _ctx.$gettext('Canceled date'), $event))),
                    type: "date",
                    modelValue: _ctx.customer.canceled_date,
                    "onUpdate:modelValue": _cache[49] || (_cache[49] = ($event: any) => ((_ctx.customer.canceled_date) = $event))
                  }, null, 8, ["disabled", "label", "required", "modelValue"]))
                : _createCommentVNode("", true),
              (_ctx.show('supportCancel') && _ctx.show('canceled_type'))
                ? (_openBlock(), _createBlock(_component_bp_select, {
                    key: 2,
                    data: _ctx.cancelledTypeStore.cancelledTypes,
                    disabled: !_ctx.selected,
                    indent: 2.375,
                    label: _ctx.$gettext('Canceled type'),
                    required: _ctx.customer.supportCancel,
                    onChangeValid: _cache[50] || (_cache[50] = ($event: any) => (_ctx.setError('canceled_type', _ctx.$gettext('Canceled type'), $event))),
                    "search-property": "name",
                    modelValue: _ctx.customer.canceled_type,
                    "onUpdate:modelValue": _cache[51] || (_cache[51] = ($event: any) => ((_ctx.customer.canceled_type) = $event))
                  }, null, 8, ["data", "disabled", "label", "required", "modelValue"]))
                : _createCommentVNode("", true),
              (_ctx.show('supportCancel') && _ctx.show('canceledTo'))
                ? (_openBlock(), _createBlock(_component_bp_input, {
                    key: 3,
                    disabled: !_ctx.selected,
                    indent: 2.375,
                    label: _ctx.$gettext('Canceled to'),
                    required: _ctx.customer.supportCancel,
                    onChangeValid: _cache[52] || (_cache[52] = ($event: any) => (_ctx.setError('canceledTo', _ctx.$gettext('Canceled to'), $event))),
                    type: "date",
                    modelValue: _ctx.customer.canceledTo,
                    "onUpdate:modelValue": _cache[53] || (_cache[53] = ($event: any) => ((_ctx.customer.canceledTo) = $event))
                  }, null, 8, ["disabled", "label", "required", "modelValue"]))
                : _createCommentVNode("", true)
            ]),
            _: 1
          }, 8, ["collapsed"]))
        : _createCommentVNode("", true),
      (_ctx.components.length > 0 && _ctx.show('products.bestinformed.installedVersion'))
        ? (_openBlock(), _createBlock(_component_translate, {
            key: 7,
            tag: `h${_ctx.headingLevel}`
          }, {
            default: _withCtx(() => _cache[60] || (_cache[60] = [
              _createTextVNode("Additional information")
            ])),
            _: 1
          }, 8, ["tag"]))
        : _createCommentVNode("", true),
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.components, (component) => {
        return (_openBlock(), _createElementBlock(_Fragment, {
          key: component._id + '_' + _ctx.getVersionNumbers(component).length
        }, [
          (_ctx.show('products.bestinformed.installedVersion'))
            ? (_openBlock(), _createBlock(_component_bp_select, {
                key: 0,
                data: _ctx.versionNumbers.get(component._id),
                disabled: !_ctx.selected,
                indent: 1.25,
                label: _ctx.$ngettext('%{component} version', '%{component} versions', _ctx.getVersionNumbers(component).length, { component: _ctx.versionStore.getName(component) }),
                text: _ctx.$ngettext('The last known version the customer has installed', 'The last known versions the customer has installed', _ctx.getVersionNumbers(component).length),
                "model-value": _ctx.getVersionNumbers(component),
                transform: _ctx.transformVersionNumber,
                "onUpdate:modelValue": ($event: any) => (_ctx.updateVersionNumbers(component, $event)),
                clearable: "",
                multiple: "",
                "sort-order": "desc",
                "sort-property": "date"
              }, _createSlots({ _: 2 }, [
                (_ctx.getVersionNumbersMeta(component).size > 0)
                  ? {
                      name: "text",
                      fn: _withCtx(() => [
                        _createElementVNode("div", _hoisted_3, [
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.getVersionNumbersMeta(component).entries(), ([key, meta]) => {
                            return (_openBlock(), _createElementBlock("span", {
                              key: key,
                              class: "flex-column gap-sm"
                            }, [
                              (_openBlock(), _createBlock(_resolveDynamicComponent(`h${_ctx.headingLevel + 1}`), null, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(_ctx.formatTransformedVersionNumber(key)), 1)
                                ]),
                                _: 2
                              }, 1024)),
                              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(Object.entries(meta), ([metaKey, metaValue]) => {
                                return (_openBlock(), _createElementBlock("p", { key: metaKey }, [
                                  _createElementVNode("strong", null, _toDisplayString(_ctx.versionStore.getTranslatedMetaKey(metaKey)) + ":", 1),
                                  _createTextVNode(" " + _toDisplayString(metaValue), 1)
                                ]))
                              }), 128))
                            ]))
                          }), 128))
                        ])
                      ]),
                      key: "0"
                    }
                  : undefined
              ]), 1032, ["data", "disabled", "label", "text", "model-value", "transform", "onUpdate:modelValue"]))
            : _createCommentVNode("", true)
        ], 64))
      }), 128))
    ]),
    _createVNode(_component_bp_window, {
      "model-value": !!_ctx.contactWindow,
      "onUpdate:modelValue": _cache[54] || (_cache[54] = ($event: any) => (_ctx.contactWindow = ''))
    }, {
      header: _withCtx(() => [
        _createVNode(_component_translate, { tag: "h2" }, {
          default: _withCtx(() => _cache[61] || (_cache[61] = [
            _createTextVNode("New contact")
          ])),
          _: 1
        })
      ]),
      footer: _withCtx(() => [
        _createVNode(_component_bp_form_button, {
          type: "save",
          errors: _ctx.contactEditor?.errors,
          action: _ctx.saveContact
        }, null, 8, ["errors", "action"]),
        _createVNode(_component_bp_form_button, {
          type: "reset",
          action: _ctx.contactEditor?.reset
        }, null, 8, ["action"]),
        _createVNode(_component_bp_form_button, {
          type: "cancel",
          action: () => _ctx.contactWindow = ''
        }, null, 8, ["action"])
      ]),
      default: _withCtx(() => [
        _createVNode(_component_bp_contact_editor, {
          ref: "contactEditor",
          unwrap: "",
          redirect: false,
          "customer-id": _ctx.customer._id
        }, null, 8, ["customer-id"])
      ]),
      _: 1
    }, 8, ["model-value"])
  ], 64))
}