import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString, resolveDynamicComponent as _resolveDynamicComponent, createElementVNode as _createElementVNode, createSlots as _createSlots } from "vue"

const _hoisted_1 = { class: "flex-column gap-md" }
const _hoisted_2 = { key: 2 }
const _hoisted_3 = { class: "bp-customer-editor-product-bestinformed__meta-grid" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_translate = _resolveComponent("translate")!
  const _component_bp_input = _resolveComponent("bp-input")!
  const _component_bp_select = _resolveComponent("bp-select")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.show(['numberOfLicences']))
      ? (_openBlock(), _createBlock(_component_translate, {
          key: 0,
          tag: "h3"
        }, {
          default: _withCtx(() => _cache[4] || (_cache[4] = [
            _createTextVNode("License")
          ])),
          _: 1
        }))
      : _createCommentVNode("", true),
    (_ctx.show('numberOfLicences'))
      ? (_openBlock(), _createBlock(_component_bp_input, {
          key: 1,
          disabled: !_ctx.selected,
          indent: 1.25,
          label: _ctx.$gettext('Number of connections'),
          min: 1,
          onChangeValid: _cache[0] || (_cache[0] = ($event: any) => (_ctx.setError('numberOfLicences', _ctx.$gettext('Number of connections'), $event))),
          type: "number",
          modelValue: _ctx.customer.numberOfLicences,
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.customer.numberOfLicences) = $event))
        }, null, 8, ["disabled", "label", "modelValue"]))
      : _createCommentVNode("", true),
    (_ctx.show(['numberOfLicences']) && _ctx.show(['supportTo']))
      ? (_openBlock(), _createElementBlock("hr", _hoisted_2))
      : _createCommentVNode("", true),
    (_ctx.show(['supportTo']))
      ? (_openBlock(), _createBlock(_component_translate, {
          key: 3,
          tag: "h3"
        }, {
          default: _withCtx(() => _cache[5] || (_cache[5] = [
            _createTextVNode("Services")
          ])),
          _: 1
        }))
      : _createCommentVNode("", true),
    (_ctx.show('supportTo'))
      ? (_openBlock(), _createBlock(_component_bp_input, {
          key: 4,
          disabled: !_ctx.selected,
          indent: 1.25,
          label: _ctx.$gettext('Support to'),
          onChangeValid: _cache[2] || (_cache[2] = ($event: any) => (_ctx.setError('supportTo', _ctx.$gettext('Support to'), $event))),
          type: "date",
          modelValue: _ctx.customer.supportTo,
          "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.customer.supportTo) = $event))
        }, null, 8, ["disabled", "label", "modelValue"]))
      : _createCommentVNode("", true),
    (_ctx.components.length > 0 && _ctx.show('products.bestproxy.installedVersion'))
      ? (_openBlock(), _createBlock(_component_translate, {
          key: 5,
          tag: `h${_ctx.headingLevel}`
        }, {
          default: _withCtx(() => _cache[6] || (_cache[6] = [
            _createTextVNode("Additional information")
          ])),
          _: 1
        }, 8, ["tag"]))
      : _createCommentVNode("", true),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.components, (component) => {
      return (_openBlock(), _createElementBlock(_Fragment, {
        key: component._id + '_' + _ctx.getVersionNumbers(component).length
      }, [
        (_ctx.show('products.bestproxy.installedVersion'))
          ? (_openBlock(), _createBlock(_component_bp_select, {
              key: 0,
              data: _ctx.versionNumbers.get(component._id),
              disabled: !_ctx.selected,
              indent: 1.25,
              label: _ctx.$ngettext('%{component} version', '%{component} versions', _ctx.getVersionNumbers(component).length, { component: _ctx.versionStore.getName(component) }),
              text: _ctx.$ngettext('The last known version the customer has installed', 'The last known versions the customer has installed', _ctx.getVersionNumbers(component).length),
              "model-value": _ctx.getVersionNumbers(component),
              transform: _ctx.transformVersionNumber,
              "onUpdate:modelValue": ($event: any) => (_ctx.updateVersionNumbers(component, $event)),
              clearable: "",
              multiple: "",
              "sort-order": "desc",
              "sort-property": "date"
            }, _createSlots({ _: 2 }, [
              (_ctx.getVersionNumbersMeta(component).size > 0)
                ? {
                    name: "text",
                    fn: _withCtx(() => [
                      _createElementVNode("div", _hoisted_3, [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.getVersionNumbersMeta(component).entries(), ([key, meta]) => {
                          return (_openBlock(), _createElementBlock("span", {
                            key: key,
                            class: "flex-column gap-sm"
                          }, [
                            (_openBlock(), _createBlock(_resolveDynamicComponent(`h${_ctx.headingLevel + 1}`), null, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(_ctx.formatTransformedVersionNumber(key)), 1)
                              ]),
                              _: 2
                            }, 1024)),
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(Object.entries(meta), ([metaKey, metaValue]) => {
                              return (_openBlock(), _createElementBlock("p", { key: metaKey }, [
                                _createElementVNode("strong", null, _toDisplayString(_ctx.versionStore.getTranslatedMetaKey(metaKey)) + ":", 1),
                                _createTextVNode(" " + _toDisplayString(metaValue), 1)
                              ]))
                            }), 128))
                          ]))
                        }), 128))
                      ])
                    ]),
                    key: "0"
                  }
                : undefined
            ]), 1032, ["data", "disabled", "label", "text", "model-value", "transform", "onUpdate:modelValue"]))
          : _createCommentVNode("", true)
      ], 64))
    }), 128))
  ]))
}