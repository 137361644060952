
import { BestinformedVersion, Component, Product, useVersionStore, VersionNumber } from '@/stores/version';
import { currentLanguageISOString, getTranslated } from '@/translation';
import { Customer, defaultCustomer, useCustomerStore } from '@/stores/customer';
import { defineComponent, onActivated, onMounted, PropType, ref, watch } from 'vue';
import { isEqual, KeyOf, LiteralUnion, OptionalKeys } from '@/utils/object';
import { PopoverElement } from '@/components/popover/BpPopoverMenu';
import { removeHTML } from '@/utils/string';
import { useAppStore, AppType } from '@/stores/app';
import { useCancelledTypeStore } from '@/stores/cancelledType';
import { useEditionStore } from '@/stores/edition';
import clone from '@sahnee/clone';
import { Breakpoint, useMedia } from '@/utils/responsive-breakpoints';
import { FormError, useFormErrors } from '@/utils/form-errors';
import { Contact, useContactStore } from '@/stores/contact';
import BpContactEditor from '@/components/contact/BpContactEditor.vue';
import { getFullSalutation } from '@/utils/user';

export default defineComponent({
  name: 'bp-customer-editor-product-bestinformed',
  components: {
    BpContactEditor: BpContactEditor
  },
  props: {
    errors: Object as PropType<Map<string, FormError>>,
    excluded: Array as PropType<(KeyOf<Customer>)[]>,
    headingLevel: { type: Number, default: 3 },
    included: Array as PropType<(KeyOf<Customer>)[]>,
    loading: Boolean,
    modelValue: Object as PropType<Partial<Customer>>,
    selected: Boolean,
    unwrap: Boolean,
    vtigerProps: Array as PropType<string[]>,
    ignoreVtiger: Boolean,
  },
  emits: [
    'change-valid',
    'update:errors',
    'update:model-value',
  ],
  setup(props, ctx) {
    ///-------------------------------------------------------------------
    /// STORE
    ///-------------------------------------------------------------------

    const contactStore = useContactStore();
    const customerStore = useCustomerStore();

    ///-------------------------------------------------------------------
    /// INCLUDED / EXCLUDED
    ///-------------------------------------------------------------------

    function show(ids: (KeyOf<Customer>) | (KeyOf<Customer>)[]) {
      if (!Array.isArray(ids)) {
        ids = [ids];
      }

      // products.bestinformed.installedVersion in included/excluded
      // included -> installedVersion + alles unterhalb includen
      // excluded -> installedVersion + alles unterhalb excluden
      //
      // show('products.bestinformed.installedVersion.server')
      // included -> starts with "products.bestinformed.installedVersion"? -> yes: include / no: don't include
      // excluded -> starts with "products.bestinformed.installedVersion"? -> yes: exclude / no: don't exclude
      //
      // show('product.bestinformed')
      // included -> is part of "products.bestinformed.installedVersion"? -> always include
      // excluded -> is part of "products.bestinformed.installedVersion"? -> always include

      for (const id of ids) {
        if (
          props.included?.find(included => included.split('.').slice(0, -1).join('.').startsWith(id)) ||
          props.excluded?.find(excluded => excluded.split('.').slice(0, -1).join('.').startsWith(id))
        ) {
          return true;
        }
      }

      if (props.included) {
        return ids.map(id => !!props.included?.find(included => id.startsWith(included))).includes(true);
      }
      if (props.excluded) {
        return ids.map(id => !props.excluded?.find(excluded => id.startsWith(excluded))).includes(true);
      }
      return true;
    }

    ///-------------------------------------------------------------------
    /// ERROR
    ///-------------------------------------------------------------------

    const { setError: updateError } = useFormErrors();

    function setError(id: LiteralUnion<KeyOf<Customer>>, name: FormError['name'], message: FormError['message']) {
      if (!props.errors) {
        return;
      }
      updateError(id, name, message, props.errors);
      ctx.emit('update:errors', props.errors);
    }

    ///-------------------------------------------------------------------
    /// VTIGER DISABLED
    ///-------------------------------------------------------------------
    
    function vtigerDisabled(id: string) {
      if (customer.value.accountid === 0) {
        return false;
      }

      if (props.vtigerProps?.includes(id)) {
        return true;
      }
    }

    ///-------------------------------------------------------------------
    /// IS DESKTOP
    ///-------------------------------------------------------------------

    const isDesktop = useMedia(Breakpoint.MIN_MD);
    const isLargeDesktop = useMedia(Breakpoint.MIN_LG);

    ///-------------------------------------------------------------------
    /// CUSTOMER
    ///-------------------------------------------------------------------

    const initialCustomer: OptionalKeys<Customer, '_id' | '_rev'> = {
      ...defaultCustomer,
      productselection: ['bestinformed'],
      supportFrom: new Date().toISOString(),
    };

    const customer = ref<OptionalKeys<Customer, '_id' | '_rev'>>({ ...clone(initialCustomer), ...clone(props.modelValue) });
    const previousCustomer = ref<OptionalKeys<Customer, '_id' | '_rev'>>({ ...clone(initialCustomer), ...clone(props.modelValue) });

    watch(() => props.modelValue, (newCustomer, oldCustomer) => {
      if (!props.modelValue || isEqual(customer.value, props.modelValue)) {
        return;
      }
      previousCustomer.value = { ...clone(initialCustomer), ...clone(oldCustomer) };
      customer.value = { ...clone(initialCustomer), ...clone(newCustomer) };
    }, { immediate: true, deep: true });

    watch(() => customer.value, () => {
      if (!props.modelValue || isEqual(customer.value, props.modelValue)) {
        return;
      }
      ctx.emit('update:model-value', customer.value);
    }, { deep: true })

    ///-------------------------------------------------------------------
    /// VERSIONS
    ///-------------------------------------------------------------------

    const versionStore = useVersionStore();

    // PRODUCT VERSIONS
    const productVersions = ref<Product[]>([]);
    // const productVersion = ref(customer.value.editionProduct !== undefined && customer.value.editionProduct !== 'Apps' ? getProductVersion(5) : getProductVersion(6));
    const productVersion = ref(customer.value.products.bestinformed.version === '5' ? getProductVersion(5) : getProductVersion(6));
    // const productVersion = ref('root');

    const preventResetBestinformedRelatedValues = ref(false);
    
    watch(() => [versionStore.isLoading(), customer.value, customer.value._id], (newValue, oldValue) => {
      if (!versionStore.loaded || versionStore.isLoading()) { return }

      const productId = versionStore.findVersionByName('bestinformed')?._id ?? 'root';
      productVersions.value = versionStore.getFlatSubtree(productId)
        .filter(product => product.doc_type === 'version_product' && product._id !== productId)
        .map(product => { delete product._children; return product as Product })
      // console.log('product versions :', productVersions.value);

      // console.log('new value', newValue);
      // console.log('new value', oldValue);
      // const newCustomer: string = newValue && newValue.length >= 3 ? newValue[2] as string : '';
      // console.log('new customer', newCustomer);
      // const oldCustomer: string = oldValue && oldValue.length >= 3 ? oldValue[2] as string : '';
      // console.log('old customer', oldCustomer);
      // console.log('customer.value.products.bestinformed.version', customer.value.products.bestinformed.version);
      // console.log('productVersion.value', productVersion.value);
      // console.log('getProductVersion(6)', getProductVersion(6));
      // preventResetBestinformedRelatedValues.value = true;
      if (customer.value.products.bestinformed.version !== (productVersion.value === getProductVersion(6) ? '6' : '5')) {
        preventResetBestinformedRelatedValues.value = true;
      }

      // if (newCustomer === oldCustomer) preventResetBestinformedRelatedValues.value = true;


      productVersion.value = customer.value.products.bestinformed.version === '5' ? getProductVersion(5) : getProductVersion(6);
      // console.log('product version :', productVersion.value === getProductVersion(6) ? '6' : '5');
      // productVersion.value = customer.value.editionProduct !== undefined && customer.value.editionProduct !== 'Apps' ? getProductVersion(5) : getProductVersion(6);
    }, { immediate: true })

    watch(() => productVersion.value, (newVersion, oldVersion) => {
      // console.log('watch -> product version :', oldVersion, oldVersion === getProductVersion(6) ? '6' : '5', '->', newVersion, newVersion === getProductVersion(6) ? '6' : '5', newVersion === oldVersion);
      customer.value.products.bestinformed.version = productVersion.value === getProductVersion(6) ? '6' : '5';
      if (oldVersion === 'root') preventResetBestinformedRelatedValues.value = true;
      // console.log('reset bestinformed related values?', (props.ignoreVtiger || customer.value.accountid === 0) && !preventResetBestinformedRelatedValues.value, { ignoreVtiger: props.ignoreVtiger, accountid: customer.value.accountid, preventResetBestinformedRelatedValues: preventResetBestinformedRelatedValues.value });
      if ((props.ignoreVtiger || customer.value.accountid === 0)) {
        resetBestinformedRelatedValues();
      }
    });

    function resetBestinformedRelatedValues() {
      // console.group('reset bestinformed related values');
      if (!preventResetBestinformedRelatedValues.value) {
        const version: BestinformedVersion = productVersion.value === getProductVersion(6) ? '6' : '5';
        
        // Reset all bestinformed related values to a the default values of the selected version
        customer.value.products.bestinformed.version = version;
        // console.log('version :', customer.value.products.bestinformed.version);
        customer.value.editionProduct = version === '6' ? 'Apps' : '';
        // console.log('edition product :', customer.value.editionProduct);
        customer.value.editionApps = '';
        // console.log('edition apps :', customer.value.editionApps);
        customer.value.apps = [];
        // console.log('apps :', customer.value.apps);

        if (version !== '6') {
          customer.value.subscription = false;
          // console.log('v6 -> subscription :', customer.value.subscription);
          customer.value.mobilecals = 0;
          // console.log('v6 -> mobilecals :', customer.value.mobilecals);
          customer.value.clusternodes = 0;
          // console.log('v6 -> clusternodes :', customer.value.clusternodes);
          customer.value.hosting = false;
          // console.log('v6 -> hosting :', customer.value.hosting);
        }
      } else {
        // console.log('reset prevented - will do it next time!');
        preventResetBestinformedRelatedValues.value = false;
      }
      // console.groupEnd();
    }

    function updateVersionErrors() {
      if (productVersion.value === getProductVersion(5)) {
        props.errors?.delete('editionApps')
      } else {
        props.errors?.delete('editionProduct')
      }
    }

    function getProductVersion(v: 5 | 6 | 7) {
      return versionStore.findVersionByName(v.toString(), productVersions.value)?._id || 'root'
    }

    // COMPONENTS
    const components = ref<Component[]>([]);
    watch(() => [versionStore.isLoading(), customer.value.editionProduct], () => {
      if (!versionStore.loaded || versionStore.isLoading()) { return }
      components.value = versionStore.getFlatSubtree(productVersion.value)
        .filter(component => component.doc_type === 'version_component')
        .map(component => { delete component._children; return component as Component });
    }, { immediate: true })

    // VERSION NUMBERS
    const versionNumbers = ref<Map<string, VersionNumber[]>>(new Map());
    watch(() => components.value, () => {
      versionNumbers.value = new Map(components.value.map(component => [
        component._id,
        versionStore.getFlatSubtree(component._id)
          .filter(versionNumber => versionNumber.doc_type === 'version_number')
          .map(versionNumber => { delete versionNumber._children; return versionNumber as VersionNumber })
      ]));
    }, { immediate: true })

    function getVersionNumbers(component: Component) {
      return customer.value.products.bestinformed.installedVersion[component.uuid] ?? [];
    }

    function updateVersionNumbers(component: Component, versionNumbers: string[]) {
      if (versionNumbers.length > 0) {
        customer.value.products.bestinformed.installedVersion[component.uuid] = versionNumbers;
      } else {
        delete customer.value.products.bestinformed.installedVersion[component.uuid];
      }
    }

    function getVersionNumbersMeta(component: Component) {
      const versionNumberIds = getVersionNumbers(component);
      const versionNumber = versionStore.getById(versionNumberIds) as VersionNumber | VersionNumber[];
      const versionNumbers = Array.isArray(versionNumber) ? versionNumber : [versionNumber];
      return new Map<string, VersionNumber['meta']>(versionNumbers
        .filter(versionNumber => versionNumber && Object.entries(versionNumber.meta).filter(
          ([key, val]) => !!versionStore.getTranslatedMetaKey(key) && !!val
        ).length > 0)
        .map(versionNumber => [transformVersionNumber(versionNumber), versionNumber.meta])
      );
    }

    function formatTransformedVersionNumber(versionNumber: string) {
      return removeHTML(versionNumber).replaceAll('&lt;', '<').replaceAll('&gt;', '>').replaceAll('&amp;', '&')
    }

    const transformVersionNumber = (versionNumber: VersionNumber) => {
      const path = versionStore.getPathById(versionNumber._id);
      const component = path
        .filter(component => component.doc_type === 'version_component')
        .map(component => versionStore.getName(component))
        .join(' ');
      // const product = path
      //   .filter(product => product.doc_type === 'version_product')
      //   .map(product => versionStore.getName(product))
      //   .join(' ');

      /*if (component && product) {
        return `${component} ${versionNumber.version} <em style="opacity: 0.5;">(${product})</em>`
      } else */if (component) {
        return `${component} ${versionNumber.version}`;
      }/* else if (product) {
        return `${versionNumber.version} <em style="opacity: 0.5;">(${product})</em>`
      }*/
      return versionNumber.version;
    }

    ///-------------------------------------------------------------------
    /// EDITIONS
    ///-------------------------------------------------------------------

    const editionStore = useEditionStore(true);

    function updateEditionApps(editionId: string) {
      customer.value.editionApps = editionId;
      customer.value.apps = [
        editionId,
        ...appStore.getAppsOfEdition(editionId).map(app => app._id),
      ];
    }

    ///-------------------------------------------------------------------
    /// APPS
    ///-------------------------------------------------------------------

    const appStore = useAppStore();

    function availableApps(appType: AppType) {
      const appsInEdition = appStore.getAppsOfEdition(customer.value.editionApps, appType).map(app => app._id);
      return clone(appStore.getAvailableAppsOfEdition(customer.value.editionApps, appType)).map(app => {
        (app as PopoverElement)._disabled = appsInEdition.includes(app._id);
        return app;
      });
    }

    function selectedApps(appType: AppType) {
      if (!customer.value.apps) {
        return [];
      }
      // return appStore.getAvailableAppsOfEdition(customer.value.editionApps, appType)
      //   .filter(app => customer.value.apps?.includes(app._id))
      //   .map(app => app._id);
      const availabelApps = appStore.getAvailableAppsOfEdition(customer.value.editionApps, appType).map(app => app._id);
      return [
        // ...appStore.getAppsOfEdition(customer.value.editionApps, appType).map(app => app._id),
        ...customer.value.apps?.filter(app => availabelApps.includes(app)),
      ];
    }

    function updateApps(appType: AppType, apps: string[]) {
      const otherApps: string[] = [];
      for (const otherAppType of Object.values(AppType)) {
        if (otherAppType !== appType) {
          otherApps.push(...selectedApps(otherAppType));
        }
      }
      customer.value.apps = [...otherApps, ...apps];
      if (!customer.value.apps.includes('mobile')) {
        customer.value.mobilecals = 0;
      }
    }

    // watch(() => customer.value.editionApps, edition => {
    //   if (edition) {
    //     customer.value.apps = [
    //       edition,
    //       ...appStore.getAppsOfEdition(customer.value.editionApps).map(app => app._id),
    //     ];
    //   }
    // }, { immediate: true });

    watch(() => customer.value.apps, apps => {
      if (apps) {
        const appsFull: Customer['apps_full'] = {};
        for (const app of apps) {
          appsFull[app] = true;
        }
        customer.value.apps_full = appsFull;
      }
    }, { immediate: true });

    ///-------------------------------------------------------------------
    /// CANCELLED
    ///-------------------------------------------------------------------

    const cancelledTypeStore = useCancelledTypeStore();

    function updateSupportCancel(supportCancel: boolean) {
      customer.value.supportCancel = supportCancel;
      if (!supportCancel) {
        customer.value.canceled_date = '';
        customer.value.canceled_name = '';
        customer.value.canceled_type = '';
        customer.value.canceledTo = '';
        // Update errors
        props.errors?.delete('canceled_date');
        props.errors?.delete('canceled_name');
        props.errors?.delete('canceled_type');
        props.errors?.delete('canceledTo');
      }
    }

    ///-------------------------------------------------------------------
    /// HOSTING
    ///-------------------------------------------------------------------

    function getHosting(product: string) {
      let productInHostingType = false;
      switch (product) {
        case 'bestinformed': {
          productInHostingType = customer.value.hosting_type === 'bestinformed' || customer.value.hosting_type === 'bestproxy_system';
          break;
        }
        case 'bestproxy': {
          productInHostingType = customer.value.hosting_type === 'poolproxy' || customer.value.hosting_type === 'bestproxy_system';
          break;
        }
      }
      return customer.value.hosting && productInHostingType
    }

    function updateHosting(product: string, value: boolean) {
      switch (product) {
        case 'bestinformed': {
          if (value) {
            customer.value.hosting_type = customer.value.hosting_type === 'poolproxy' ? 'bestproxy_system' : 'bestinformed';
          } else {
            customer.value.hosting_type = customer.value.hosting_type === 'bestproxy_system' ? 'poolproxy' : '';
          }
          break;
        }
        case 'bestproxy': {
          if (value) {
            customer.value.hosting_type = customer.value.hosting_type === 'bestinformed' ? 'bestproxy_system' : 'poolproxy';
          } else {
            customer.value.hosting_type = customer.value.hosting_type === 'bestproxy_system' ? 'bestinformed' : '';
          }
          break;
        }
      }
      if (!customer.value.hosting_type) {
        customer.value.hostingruntimeto = '';
        props.errors?.delete('hostingruntimeto');
      }
      customer.value.hosting = !!customer.value.hosting_type;
    }

    function updateHostingCancel(hostingCancel: boolean) {
      customer.value.hostingCancel = hostingCancel;
      if (!hostingCancel) {
        customer.value.hosting_canceled_date = '';
        customer.value.hosting_canceled_name = '';
        customer.value.hosting_canceled_type = '';
        customer.value.hosting_canceledTo = '';
        // Update errors
        props.errors?.delete('hosting_canceled_date');
        props.errors?.delete('hosting_canceled_name');
        props.errors?.delete('hosting_canceled_type');
        props.errors?.delete('hosting_canceledTo');
      }
    }

    ///-------------------------------------------------------------------
    /// SUPPORT TO TEMPLATES
    ///-------------------------------------------------------------------

    function updateSupportTo(years: number) {
      const startDate = customer.value.supportFrom ? new Date(clone(customer.value.supportFrom)) : new Date();
      let endDate = new Date(Date.UTC(startDate.getFullYear() + years, startDate.getMonth(), startDate.getDate() - 1));
      endDate.setUTCHours(0, 0, 0);
      customer.value.supportTo = endDate.toISOString();
    }

    ///-------------------------------------------------------------------
    /// TRANSFORM CONTACT
    ///-------------------------------------------------------------------

    function transformContact(contact: Contact) {
      return getFullSalutation(contact);
    }

    ///-------------------------------------------------------------------
    /// CONTACT WINDOW
    ///-------------------------------------------------------------------

    const contactWindow = ref('');

    const contactEditor = ref<typeof BpContactEditor>();

    async function saveContact() {
      const key = contactWindow.value === 'hosting' ? 'hosting_canceled_name' : 'canceled_name';
      const contact = await contactEditor.value?.save();
      customer.value[key] = contact._id;
      props.errors?.delete(key);
      contactWindow.value = '';
    }

    ///-------------------------------------------------------------------
    /// RETURN
    ///-------------------------------------------------------------------

    return {
      appStore,
      AppType,
      availableApps,
      cancelledTypeStore,
      components,
      currentLanguageISOString,
      customer,
      contactStore,
      contactEditor,
      contactWindow,
      customerStore,
      saveContact,
      editionStore,
      formatTransformedVersionNumber,
      getHosting,
      getTranslated,
      getVersionNumbers,
      getVersionNumbersMeta,
      isDesktop,
      isLargeDesktop,
      productVersion,
      productVersions,
      selectedApps,
      setError,
      show,
      transformVersionNumber,
      transformContact,
      updateApps,
      updateEditionApps,
      updateHosting,
      updateHostingCancel,
      updateSupportCancel,
      updateSupportTo,
      updateVersionErrors,
      updateVersionNumbers,
      versionNumbers,
      versionStore,
      vtigerDisabled,
      getProductVersion,
    }
  }
})
