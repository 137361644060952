
import { $gettext } from 'vue-gettext';
import { computed, defineComponent, PropType, ref, watch } from 'vue';
import { Customer, defaultCustomer, useCustomerStore } from '@/stores/customer';
import { FormError, useFormErrors } from '@/utils/form-errors';
import { isEqual, KeyOf, LiteralUnion, OptionalKeys } from '@/utils/object';
import { useCountryStore } from '@/stores/country';
import { useRouter } from 'vue-router';
import BpCustomerEditorProductBestinformedVue from './products/BpCustomerEditorProductBestinformed.vue';
import BpCustomerEditorProductBestproxyVue from './products/BpCustomerEditorProductBestproxy.vue';
import BpCustomerEditorProductBestzeroVue from './products/BpCustomerEditorProductBestzero.vue';
import BpToast from '../toast/BpToasts';
import clone from '@sahnee/clone';
import useCompactMode from '@/compositions/use-compact-mode';
import { useVersionStore } from '@/stores/version';

export default defineComponent({
  name: 'bp-customer-editor',
  components: {
    BpCustomerEditorProductBestinformed: BpCustomerEditorProductBestinformedVue,
    BpCustomerEditorProductBestproxy: BpCustomerEditorProductBestproxyVue,
    BpCustomerEditorProductBestzero: BpCustomerEditorProductBestzeroVue,
  },
  props: {
    modelValue: Object as PropType<Partial<Customer>>,
    loading: Boolean,
    unwrap: Boolean,
    included: Array as PropType<(KeyOf<Customer>)[]>,
    excluded: Array as PropType<(KeyOf<Customer>)[]>,
    ignoreVtiger: Boolean,
  },
  emits: [
    'update:model-value',
    'change-valid'
  ],
  setup(props, ctx) {
    ///-------------------------------------------------------------------
    /// ROUTER
    ///-------------------------------------------------------------------

    const router = useRouter();
    const isEdit = computed(() => router.currentRoute.value.name?.toString().endsWith('edit'));

    ///-------------------------------------------------------------------
    /// INCLUDED / EXCLUDED
    ///-------------------------------------------------------------------

    function show(ids: (KeyOf<Customer>) | (KeyOf<Customer>)[]) {
      if (!Array.isArray(ids)) {
        ids = [ids];
      }

      // NESTED
      for (const id of ids) {
        if (
          props.included?.find(included => included.split('.').slice(0, -1).join('.').startsWith(id)) ||
          props.excluded?.find(excluded => excluded.split('.').slice(0, -1).join('.').startsWith(id))
        ) {
          return true;
        }
      }

      // INCLUDED
      if (props.included) {
        return ids.map(id => !!props.included?.find(included => id.startsWith(included))).includes(true);
      }

      // EXCLUDED
      if (props.excluded) {
        return ids.map(id => !props.excluded?.find(excluded => id.startsWith(excluded))).includes(true);
      }

      return true;
    }

    ///-------------------------------------------------------------------
    /// CUSTOMER
    ///-------------------------------------------------------------------

    const initialCustomer: OptionalKeys<Customer, '_id' | '_rev'> = {
      ...defaultCustomer,
      productselection: ['bestinformed'],
      supportFrom: new Date().toISOString(),
    };

    const originalCustomer = ref<OptionalKeys<Customer, '_id' | '_rev'>>({ ...clone(initialCustomer), ...props.modelValue ? clone(props.modelValue) : {
      editionProduct: 'Apps',
      editionApps: 'standard',
    } });
    const customer = ref<OptionalKeys<Customer, '_id' | '_rev'>>({ ...clone(initialCustomer), ...props.modelValue ? clone(props.modelValue) : {
      editionProduct: 'Apps',
      editionApps: 'standard',
    } });
    watch(() => props.modelValue === undefined, () => {
      if (!props.modelValue) { return }
      originalCustomer.value = { ...clone(initialCustomer), ...props.modelValue ? clone(props.modelValue) : {
        editionProduct: 'Apps',
        editionApps: 'standard',
      } };
    }, { immediate: true })
    watch(() => props.modelValue, () => {
      if (!props.modelValue) { return }
      customer.value = { ...clone(initialCustomer), ...props.modelValue ? clone(props.modelValue) : {
        editionProduct: 'Apps',
        editionApps: 'standard',
      } };
    }, { immediate: true, deep: true });

    const customerAddress = computed(() => {
      return [customer.value.street1, customer.value.street2, customer.value.street3].filter(street => street).join('\n');
    })

    function updateAddress(address: string) {
      const streets = address.split('\n').filter(line => line);
      for (let i = 0; i < 3; i++) {
        const key = 'street' + (i + 1) as 'street1' | 'street2' | 'street3';
        customer.value[key] = streets[i] || '';
      }
    }

    function updateCustomer(value: OptionalKeys<Customer, '_id' | '_rev'>) {
      if (isEqual(customer.value, value)) {
        return;
      }
      customer.value = value;
    }

    watch(() => customer.value, () => {
      if (!props.modelValue || isEqual(customer.value, props.modelValue)) {
        return;
      }
      ctx.emit('update:model-value', customer.value);
    }, { deep: true })

    ///-------------------------------------------------------------------
    /// RESET
    ///-------------------------------------------------------------------

    function reset() {
      if (originalCustomer.value && isEqual(customer.value, originalCustomer.value)) { return }
      customer.value = clone(originalCustomer.value);
    }

    ///-------------------------------------------------------------------
    /// STREETS
    ///-------------------------------------------------------------------

    const showStreet2 = ref<boolean>(!!customer.value.street2);
    const showStreet3 = ref<boolean>(!!customer.value.street3);

    ///-------------------------------------------------------------------
    /// COUNTRIES
    ///-------------------------------------------------------------------

    const countryStore = useCountryStore();

    ///-------------------------------------------------------------------
    /// PRODUCTS
    ///-------------------------------------------------------------------

    const productTabs = [
      { _id: 'bestinformed', name: 'bestinformed' },
      { _id: 'bestproxy', name: 'bestproxy' },
      { _id: 'bestzero', name: 'bestzero' }
    ];

    const activeTab = ref((() => {
      // TODO: Currently which tab is selected is based on the current `editionProduct`. It should be dependent on the `productselection`.
      if (customer.value.editionProduct === 'bestzero') {
        return 'bestzero';
      }

      // TODO: This is the correct logic which should apply. We don't need the `editionProduct` to select the correct tab. Unfortunately this is not the logic of portal 1.0.
      if (Array.isArray(customer.value.productselection)) {
        return customer.value.productselection.length > 0 ? customer.value.productselection[0] : undefined;
      } else {
        return customer.value.productselection || undefined
      }
    })())

    function updateProductselection(productId: string) {
      if (!customer.value.productselection) {
        customer.value.productselection = [];
      }

      // Currently 'bestzero' cannot be paired with other products, therefore we have create a new selection.
      if (productId === 'bestzero') {
        if (store.hasProduct(customer.value, 'bestzero')) {
          customer.value.productselection = [];
        } else {
          customer.value.productselection = ['bestzero'];
        }
        // We have to also update the `edtitionProduct` key.
        customer.value.editionProduct = 'bestzero';
        customer.value.bestinformed = false;
        customer.value.bestproxy = false;
        // Currently there is no explicit hosting type for the product 'bestzero' we have to clear that key;
        customer.value.hosting_type = '';
      }
      // Otherwise we toggle the products in the selection.
      else {
        const product = productId as 'bestinformed' | 'bestproxy';
        // If 'bestzero' was previously selected, we create a new selection with the newly toggled product.
        if (store.hasProduct(customer.value, 'bestzero')) {
          customer.value.productselection = [product];
          customer.value[product] = true;
        } else if (store.hasProduct(customer.value, productId)) {
          if (Array.isArray(customer.value.productselection)) {
            customer.value.productselection.splice(customer.value.productselection.findIndex(pId => pId === product), 1);
          } else {
            customer.value.productselection = [];
          }
          customer.value[product] = false;
          // // TODO: Update errors correctly!!
          // if (productId === 'bestinformed') {
          //   errors.value.delete('canceled_date');
          //   errors.value.delete('canceled_name');
          //   errors.value.delete('canceled_type');
          //   errors.value.delete('canceledTo');
          // }
          // errors.value.delete('hosting_canceled_date');
          // errors.value.delete('hosting_canceled_name');
          // errors.value.delete('hosting_canceled_type');
          // errors.value.delete('hosting_canceledTo');
        } else if (Array.isArray(customer.value.productselection)) {
          customer.value.productselection.push(product);
          customer.value[product] = true;
        } else {
          customer.value.productselection = [product]
          customer.value[product] = true;
        }
        // We have to also update the `edtitionProduct` key.
        customer.value.editionProduct = 'Apps';
      }
    }

    ///-------------------------------------------------------------------
    /// ERROR
    ///-------------------------------------------------------------------

    const { errors, setError: updateError } = useFormErrors<Customer>();

    function setError(id: LiteralUnion<KeyOf<Customer>>, name: FormError['name'], message: FormError['message']) {
      updateError(id, name, message);
      ctx.emit('change-valid', id, name, message)
    }

    ///-------------------------------------------------------------------
    /// STORE
    ///-------------------------------------------------------------------

    const store = useCustomerStore();
    const versionStore = useVersionStore();

    ///-------------------------------------------------------------------
    /// COMPANY
    ///-------------------------------------------------------------------

    function companyExists() {
      return store.companyExists(customer.value.company || '', isEdit.value ? customer.value._id : []);
    }

    ///-------------------------------------------------------------------
    /// SAVE
    ///-------------------------------------------------------------------

    async function save() {
      let response;
      if (isEdit.value) {
        response = await store.update(customer.value);
      } else {
        response = await store.create(customer.value);
      }
      if (response?.success) {
        BpToast.show({
          color: 'green',
          title: customer.value._id
            ? $gettext('Customer successfully updated')
            : $gettext('Customer successfully created'),
          content: customer.value._id
            ? $gettext('The customer <strong>%{company}</strong> was successfully updated.', { company: customer.value.company })
            : $gettext('The customer <strong>%{company}</strong> was successfully created.', { company: customer.value.company }),
          icon: 'circle-check',
        });
        router.replace(isEdit.value ? { name: 'admin.customer.overview' } : { name: 'admin.contact.new', query: { customer: response.data?._id } });
      } else {
        BpToast.show({
          color: 'red',
          title: customer.value._id
            ? $gettext('Failed to update customer')
            : $gettext('Failed to create customer'),
          content: customer.value._id
            ? $gettext('The customer <strong>%{company}</strong> could not be updated: %{error}', { company: customer.value.company, error: response?.error })
            : $gettext('The customer <strong>%{company}</strong> could not be created: %{error}', { company: customer.value.company, error: response?.error }),
          icon: 'circle-check',
        });
      }
    }

    ///-------------------------------------------------------------------
    /// APPEARANCE
    ///-------------------------------------------------------------------

    const { current: compactMode } = useCompactMode();

    ///-------------------------------------------------------------------
    /// RETURN
    ///-------------------------------------------------------------------

    return {
      activeTab,
      compactMode,
      companyExists,
      countryStore,
      customer,
      customerAddress,
      errors,
      isEdit,
      productTabs,
      reset,
      save,
      setError,
      show,
      showStreet2,
      showStreet3,
      store,
      updateAddress,
      updateCustomer,
      updateProductselection,
      versionStore,
    };
  }
})
