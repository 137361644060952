
import { Component, Product, useVersionStore, VersionNumber } from '@/stores/version';
import { Customer, defaultCustomer, useCustomerStore } from '@/stores/customer';
import { defineComponent, PropType, ref, watch } from 'vue';
import { FormError, useFormErrors } from '@/utils/form-errors';
import { getTranslated } from '@/translation';
import { isEqual, KeyOf, LiteralUnion, OptionalKeys } from '@/utils/object';
import { removeHTML } from '@/utils/string';
import { useCancelledTypeStore } from '@/stores/cancelledType';
import clone from '@sahnee/clone';
import { Contact, useContactStore } from '@/stores/contact';
import BpContactEditor from '@/components/contact/BpContactEditor.vue';
import { getFullSalutation } from '@/utils/user';

export default defineComponent({
  name: 'bp-customer-editor-product-bestproxy',
  components: {
    BpContactEditor: BpContactEditor
  },
  props: {
    errors: Object as PropType<Map<string, FormError>>,
    excluded: Array as PropType<(KeyOf<Customer>)[]>,
    headingLevel: { type: Number, default: 3 },
    included: Array as PropType<(KeyOf<Customer>)[]>,
    loading: Boolean,
    modelValue: Object as PropType<Partial<Customer>>,
    selected: Boolean,
    unwrap: Boolean,
  },
  emits: [
    'change-valid',
    'update:errors',
    'update:model-value',
  ],
  setup(props, ctx) {

    ///-------------------------------------------------------------------
    /// STORES
    ///-------------------------------------------------------------------

    const contactStore = useContactStore();
    const customerStore = useCustomerStore();

    ///-------------------------------------------------------------------
    /// INCLUDED / EXCLUDED
    ///-------------------------------------------------------------------

    function show(ids: (KeyOf<Customer>) | (KeyOf<Customer>)[]) {
      if (!Array.isArray(ids)) {
        ids = [ids];
      }

      // products.bestinformed.installedVersion in included/excluded
      // included -> installedVersion + alles unterhalb includen
      // excluded -> installedVersion + alles unterhalb excluden
      //
      // show('products.bestinformed.installedVersion.server')
      // included -> starts with "products.bestinformed.installedVersion"? -> yes: include / no: don't include
      // excluded -> starts with "products.bestinformed.installedVersion"? -> yes: exclude / no: don't exclude
      //
      // show('product.bestinformed')
      // included -> is part of "products.bestinformed.installedVersion"? -> always include
      // excluded -> is part of "products.bestinformed.installedVersion"? -> always include

      for (const id of ids) {
        if (
          props.included?.find(included => included.split('.').slice(0, -1).join('.').startsWith(id)) ||
          props.excluded?.find(excluded => excluded.split('.').slice(0, -1).join('.').startsWith(id))
        ) {
          return true;
        }
      }

      if (props.included) {
        return ids.map(id => !!props.included?.find(included => id.startsWith(included))).includes(true);
      }
      if (props.excluded) {
        return ids.map(id => !props.excluded?.find(excluded => id.startsWith(excluded))).includes(true);
      }
      return true;
    }

    ///-------------------------------------------------------------------
    /// ERROR
    ///-------------------------------------------------------------------

    const { setError: updateError } = useFormErrors();

    function setError(id: LiteralUnion<KeyOf<Customer>>, name: FormError['name'], message: FormError['message']) {
      if (!props.errors) {
        return;
      }
      updateError(id, name, message, props.errors);
      ctx.emit('update:errors', props.errors);
    }

    ///-------------------------------------------------------------------
    /// CUSTOMER
    ///-------------------------------------------------------------------

    const initialCustomer: OptionalKeys<Customer, '_id' | '_rev'> = {
      ...defaultCustomer,
      productselection: ['bestinformed'],
      supportFrom: new Date().toISOString(),
    };

    const customer = ref<OptionalKeys<Customer, '_id' | '_rev'>>({ ...clone(initialCustomer), ...clone(props.modelValue) });

    watch(() => props.modelValue, () => {
      if (!props.modelValue || isEqual(customer.value, props.modelValue)) {
        return;
      }
      customer.value = { ...clone(initialCustomer), ...clone(props.modelValue) };
    }, { immediate: true, deep: true });

    watch(() => customer.value, () => {
      if (!props.modelValue || isEqual(customer.value, props.modelValue)) {
        return;
      }
      ctx.emit('update:model-value', customer.value)
    }, { deep: true })

    function updateWebaccess() {
      if (!customer.value.webaccess) {
        customer.value.bestproxywebaccess = 0;
      } else {
        customer.value.bestproxywebaccess = 1;
      }
    }

    ///-------------------------------------------------------------------
    /// CANCELLED TYPE
    ///-------------------------------------------------------------------

    const cancelledTypeStore = useCancelledTypeStore();

    ///-------------------------------------------------------------------
    /// HOSTING
    ///-------------------------------------------------------------------

    function getHosting(product: string) {
      let productInHostingType = false;
      switch (product) {
        case 'bestinformed': {
          productInHostingType = customer.value.hosting_type === 'bestinformed' || customer.value.hosting_type === 'bestproxy_system';
          break;
        }
        case 'bestproxy': {
          productInHostingType = customer.value.hosting_type === 'poolproxy' || customer.value.hosting_type === 'bestproxy_system';
          break;
        }
      }
      return customer.value.hosting && productInHostingType
    }

    function updateHosting(product: string, value: boolean) {
      switch (product) {
        case 'bestinformed': {
          if (value) {
            customer.value.hosting_type = customer.value.hosting_type === 'poolproxy' ? 'bestproxy_system' : 'bestinformed';
          } else {
            customer.value.hosting_type = customer.value.hosting_type === 'bestproxy_system' ? 'poolproxy' : '';
          }
          break;
        }
        case 'bestproxy': {
          if (value) {
            customer.value.hosting_type = customer.value.hosting_type === 'bestinformed' ? 'bestproxy_system' : 'poolproxy';
          } else {
            customer.value.hosting_type = customer.value.hosting_type === 'bestproxy_system' ? 'bestinformed' : '';
          }
          break;
        }
      }
      if (!customer.value.hosting_type) {
        customer.value.hostingruntimeto = '';
        props.errors?.delete('hostingruntimeto');
      }
      customer.value.hosting = !!customer.value.hosting_type;
    }

    function updateHostingCancel(hostingCancel: boolean) {
      customer.value.hostingCancel = hostingCancel;
      if (!hostingCancel) {
        customer.value.hosting_canceled_date = '';
        customer.value.hosting_canceled_name = '';
        customer.value.hosting_canceled_type = '';
        customer.value.hosting_canceledTo = '';
        // Update errors
        props.errors?.delete('hosting_canceled_date')
        props.errors?.delete('hosting_canceled_name')
        props.errors?.delete('hosting_canceled_type')
        props.errors?.delete('hosting_canceledTo')
      }
    }

    ///-------------------------------------------------------------------
    /// VERSIONS
    ///-------------------------------------------------------------------

    const versionStore = useVersionStore();

    // PRODUCT
    const product = ref<Product>();
    watch(() => versionStore.isLoading(), () => {
      if (!versionStore.loaded || versionStore.isLoading()) { return }
      product.value = versionStore.getByUUIDPath('bestproxy') as Product;
    }, { immediate: true })

    // COMPONENTS
    const components = ref<Component[]>([]);
    watch(() => versionStore.isLoading(), () => {
      if (!versionStore.loaded || versionStore.isLoading()) { return }
      components.value = versionStore.getFlatSubtree(product.value?._id || '')
        .filter(component => component.doc_type === 'version_component')
        .map(component => { delete component._children; return component as Component });
    }, { immediate: true })

    // VERSION NUMBERS
    const versionNumbers = ref<Map<string, VersionNumber[]>>(new Map());
    watch(() => components.value, () => {
      versionNumbers.value = new Map(components.value.map(component => [
        component._id,
        versionStore.getFlatSubtree(component._id)
          .filter(versionNumber => versionNumber.doc_type === 'version_number')
          .map(versionNumber => { delete versionNumber._children; return versionNumber as VersionNumber })
      ]));
    }, { immediate: true })

    function getVersionNumbers(component: Component) {
      return customer.value.products.bestproxy.installedVersion[component.uuid] ?? [];
    }

    function updateVersionNumbers(component: Component, versionNumbers: string[]) {
      if (versionNumbers.length > 0) {
        customer.value.products.bestproxy.installedVersion[component.uuid] = versionNumbers;
      } else {
        delete customer.value.products.bestproxy.installedVersion[component.uuid];
      }
    }

    function getVersionNumbersMeta(component: Component) {
      const versionNumberIds = getVersionNumbers(component);
      const versionNumber = versionStore.getById(versionNumberIds) as VersionNumber | VersionNumber[];
      const versionNumbers = Array.isArray(versionNumber) ? versionNumber : [versionNumber];
      return new Map<string, VersionNumber['meta']>(versionNumbers
        .filter(versionNumber => Object.entries(versionNumber.meta).filter(
          ([key, val]) => !!versionStore.getTranslatedMetaKey(key) && !!val
        ).length > 0)
        .map(versionNumber => [transformVersionNumber(versionNumber), versionNumber.meta])
      );
    }

    function formatTransformedVersionNumber(versionNumber: string) {
      return removeHTML(versionNumber).replaceAll('&lt;', '<').replaceAll('&gt;', '>').replaceAll('&amp;', '&')
    }

    const transformVersionNumber = (versionNumber: VersionNumber) => {
      const path = versionStore.getPathById(versionNumber._id);
      const component = path
        .filter(component => component.doc_type === 'version_component')
        .map(component => versionStore.getName(component))
        .join(' ');
      // const product = path
      //   .filter(product => product.doc_type === 'version_product')
      //   .map(product => versionStore.getName(product))
      //   .join(' ');

      /*if (component && product) {
        return `${component} ${versionNumber.version} <em style="opacity: 0.5;">(${product})</em>`
      } else */if (component) {
        return `${component} ${versionNumber.version}`;
      }/* else if (product) {
        return `${versionNumber.version} <em style="opacity: 0.5;">(${product})</em>`
      }*/
      return versionNumber.version;
    }

    function transformContact(contact: Contact) {
      return getFullSalutation(contact);
    }

    ///-------------------------------------------------------------------
    /// CONTACT WINDOW
    ///-------------------------------------------------------------------

    const contactWindow = ref('');

    const contactEditor = ref<typeof BpContactEditor>();

    async function saveContact() {
      const key = contactWindow.value === 'hosting' ? 'hosting_canceled_name' : 'canceled_name';
      const contact = await contactEditor.value?.save();
      customer.value[key] = contact._id;
      props.errors?.delete(key);
      contactWindow.value = '';
    }

    return {
      cancelledTypeStore,
      components,
      contactEditor,
      contactStore,
      contactWindow,
      customer,
      customerStore,
      formatTransformedVersionNumber,
      getHosting,
      getTranslated,
      getVersionNumbers,
      getVersionNumbersMeta,
      saveContact,
      setError,
      show,
      transformContact,
      transformVersionNumber,
      updateHosting,
      updateHostingCancel,
      updateVersionNumbers,
      updateWebaccess,
      versionNumbers,
      versionStore,
    }
  }
})
