import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementBlock as _createElementBlock, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString, resolveDynamicComponent as _resolveDynamicComponent, createElementVNode as _createElementVNode, createSlots as _createSlots } from "vue"

const _hoisted_1 = { class: "flex-column gap-md" }
const _hoisted_2 = { key: 6 }
const _hoisted_3 = { class: "bp-customer-editor-product-bestinformed__meta-grid" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_translate = _resolveComponent("translate")!
  const _component_bp_input = _resolveComponent("bp-input")!
  const _component_bp_tooltip = _resolveComponent("bp-tooltip")!
  const _component_bp_select = _resolveComponent("bp-select")!
  const _component_bp_button = _resolveComponent("bp-button")!
  const _component_bp_contact_editor = _resolveComponent("bp-contact-editor")!
  const _component_bp_form_button = _resolveComponent("bp-form-button")!
  const _component_bp_window = _resolveComponent("bp-window")!
  const _component_bp_label = _resolveComponent("bp-label")!
  const _component_bp_fieldset = _resolveComponent("bp-fieldset")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.show(['bestproxycores', 'bestproxyram', 'bestproxyruntimeto', 'webaccess', 'bestproxywebaccess']))
      ? (_openBlock(), _createBlock(_component_translate, {
          key: 0,
          tag: "h3"
        }, {
          default: _withCtx(() => _cache[25] || (_cache[25] = [
            _createTextVNode("License")
          ])),
          _: 1
        }))
      : _createCommentVNode("", true),
    (_ctx.show('bestproxycores'))
      ? (_openBlock(), _createBlock(_component_bp_input, {
          key: 1,
          disabled: !_ctx.selected,
          indent: 1.25,
          label: _ctx.$gettext('Amount of cores'),
          min: 1,
          onChangeValid: _cache[0] || (_cache[0] = ($event: any) => (_ctx.setError('bestproxycores', _ctx.$gettext('Amount of cores'), $event))),
          type: "number",
          modelValue: _ctx.customer.bestproxycores,
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.customer.bestproxycores) = $event))
        }, null, 8, ["disabled", "label", "modelValue"]))
      : _createCommentVNode("", true),
    (_ctx.show('bestproxyram'))
      ? (_openBlock(), _createBlock(_component_bp_input, {
          key: 2,
          disabled: !_ctx.selected,
          indent: 1.25,
          label: _ctx.$gettext('RAM in GB'),
          min: 1,
          onChangeValid: _cache[2] || (_cache[2] = ($event: any) => (_ctx.setError('bestproxyram', _ctx.$gettext('RAM in GB'), $event))),
          type: "number",
          modelValue: _ctx.customer.bestproxyram,
          "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.customer.bestproxyram) = $event))
        }, null, 8, ["disabled", "label", "modelValue"]))
      : _createCommentVNode("", true),
    (_ctx.show('bestproxyruntimeto'))
      ? (_openBlock(), _createBlock(_component_bp_input, {
          key: 3,
          disabled: !_ctx.selected,
          indent: 1.25,
          label: _ctx.$gettext('Valid until'),
          onChangeValid: _cache[4] || (_cache[4] = ($event: any) => (_ctx.setError('bestproxyruntimeto', _ctx.$gettext('Valid until'), $event))),
          required: _ctx.selected,
          type: "date",
          modelValue: _ctx.customer.bestproxyruntimeto,
          "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.customer.bestproxyruntimeto) = $event))
        }, null, 8, ["disabled", "label", "required", "modelValue"]))
      : _createCommentVNode("", true),
    (_ctx.show('webaccess'))
      ? (_openBlock(), _createBlock(_component_bp_input, {
          key: 4,
          disabled: !_ctx.selected,
          indent: 1.25,
          label: _ctx.$gettext('With web access'),
          text: _ctx.$gettext('The customer has access to the web interface'),
          onChangeValid: _cache[6] || (_cache[6] = ($event: any) => (_ctx.setError('webaccess', _ctx.$gettext('With web access'),  $event))),
          flush: "",
          type: "checkbox",
          modelValue: _ctx.customer.webaccess,
          "onUpdate:modelValue": [
            _cache[7] || (_cache[7] = ($event: any) => ((_ctx.customer.webaccess) = $event)),
            _ctx.updateWebaccess
          ]
        }, null, 8, ["disabled", "label", "text", "modelValue", "onUpdate:modelValue"]))
      : _createCommentVNode("", true),
    (_ctx.show('bestproxywebaccess'))
      ? (_openBlock(), _createBlock(_component_bp_tooltip, {
          key: 5,
          condition: !_ctx.selected || !_ctx.customer.webaccess,
          tooltip: _ctx.$gettext('"With web access" is required')
        }, {
          default: _withCtx(() => [
            _createVNode(_component_bp_input, {
              disabled: !_ctx.selected || !_ctx.customer.webaccess,
              indent: 1.25,
              label: _ctx.$gettext('Amount of concurrent web accesses'),
              min: _ctx.customer.webaccess ? 1 : 0,
              onChangeValid: _cache[8] || (_cache[8] = ($event: any) => (_ctx.setError('bestproxywebaccess', _ctx.$gettext('Amount of concurrent web accesses'), $event))),
              type: "number",
              modelValue: _ctx.customer.bestproxywebaccess,
              "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.customer.bestproxywebaccess) = $event))
            }, null, 8, ["disabled", "label", "min", "modelValue"])
          ]),
          _: 1
        }, 8, ["condition", "tooltip"]))
      : _createCommentVNode("", true),
    (_ctx.show(['bestproxycores', 'bestproxyram', 'bestproxyruntimeto', 'webaccess', 'bestproxywebaccess']) && _ctx.show(['hosting', 'hostingruntimeto', 'hostingCancel', 'hosting_canceled_name', 'hosting_canceled_date', 'hosting_canceled_type', 'hosting_canceledTo']))
      ? (_openBlock(), _createElementBlock("hr", _hoisted_2))
      : _createCommentVNode("", true),
    (_ctx.show(['hosting', 'hostingruntimeto', 'hostingCancel', 'hosting_canceled_name', 'hosting_canceled_date', 'hosting_canceled_type', 'hosting_canceledTo']))
      ? (_openBlock(), _createBlock(_component_translate, {
          key: 7,
          tag: "h4"
        }, {
          default: _withCtx(() => _cache[26] || (_cache[26] = [
            _createTextVNode("Services")
          ])),
          _: 1
        }))
      : _createCommentVNode("", true),
    (_ctx.show('hosting'))
      ? (_openBlock(), _createBlock(_component_bp_fieldset, {
          key: 8,
          collapsed: !_ctx.getHosting('bestproxy'),
          "intense-border": ""
        }, {
          legend: _withCtx(() => [
            (_ctx.show('hosting'))
              ? (_openBlock(), _createBlock(_component_bp_input, {
                  key: 0,
                  disabled: !_ctx.selected,
                  "model-value": _ctx.getHosting('bestproxy'),
                  text: _ctx.$gettext('Hosting'),
                  onChangeValid: _cache[10] || (_cache[10] = ($event: any) => (_ctx.setError('hostingCancel', _ctx.$gettext('Hosting'), $event))),
                  "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => (_ctx.updateHosting('bestproxy', $event))),
                  flush: "",
                  type: "checkbox"
                }, null, 8, ["disabled", "model-value", "text"]))
              : _createCommentVNode("", true)
          ]),
          default: _withCtx(() => [
            (_ctx.show('hosting') && _ctx.show('hostingruntimeto'))
              ? (_openBlock(), _createBlock(_component_bp_input, {
                  key: 0,
                  disabled: !_ctx.selected,
                  indent: 2.375,
                  required: _ctx.getHosting('bestproxy'),
                  text: _ctx.customerStore.hasProduct(_ctx.customer, 'bestinformed') && _ctx.getHosting('bestinformed') ? _ctx.$gettext('The runtime is linked to the hosting runtime of %{product}', { product: 'bestinformed<sup>®</sup>' }) : undefined,
                  onChangeValid: _cache[12] || (_cache[12] = ($event: any) => (_ctx.setError('hostingruntimeto', _ctx.$gettext('Runtime to'), $event))),
                  type: "date",
                  modelValue: _ctx.customer.hostingruntimeto,
                  "onUpdate:modelValue": _cache[13] || (_cache[13] = ($event: any) => ((_ctx.customer.hostingruntimeto) = $event))
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_translate, null, {
                      default: _withCtx(() => _cache[27] || (_cache[27] = [
                        _createTextVNode("Runtime to")
                      ])),
                      _: 1
                    })
                  ]),
                  _: 1
                }, 8, ["disabled", "required", "text", "modelValue"]))
              : _createCommentVNode("", true),
            (_ctx.show('hosting') && _ctx.show('hostingCancel') && !!_ctx.modelValue?._id)
              ? (_openBlock(), _createBlock(_component_bp_fieldset, {
                  key: 1,
                  collapsed: !_ctx.customer.hostingCancel,
                  "intense-border": ""
                }, {
                  legend: _withCtx(() => [
                    (_ctx.show('hosting') && _ctx.show('hostingCancel'))
                      ? (_openBlock(), _createBlock(_component_bp_input, {
                          key: 0,
                          disabled: !_ctx.selected,
                          indent: 3.5,
                          text: _ctx.$gettext('The customer has canceled hosting'),
                          onChangeValid: _cache[14] || (_cache[14] = ($event: any) => (_ctx.setError('hostingCancel', _ctx.$gettext('The customer has canceled hosting'), $event))),
                          flush: "",
                          type: "checkbox",
                          "model-value": _ctx.customer.hostingCancel,
                          "onUpdate:modelValue": _ctx.updateHostingCancel
                        }, null, 8, ["disabled", "text", "model-value", "onUpdate:modelValue"]))
                      : _createCommentVNode("", true)
                  ]),
                  default: _withCtx(() => [
                    (_ctx.show('hosting') && _ctx.show('hostingCancel') && _ctx.show('hosting_canceled_name'))
                      ? (_openBlock(), _createBlock(_component_bp_label, {
                          key: 0,
                          indent: 3.5,
                          label: _ctx.$gettext('Canceled from'),
                          required: _ctx.customer.hostingCancel
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_bp_select, {
                              data: _ctx.contactStore.getByCustomerId(_ctx.customer._id || ''),
                              disabled: !_ctx.selected,
                              required: _ctx.customer.hostingCancel,
                              transform: _ctx.transformContact,
                              onChangeValid: _cache[15] || (_cache[15] = ($event: any) => (_ctx.setError('hosting_canceled_name', _ctx.$gettext('Canceled from'), $event))),
                              modelValue: _ctx.customer.hosting_canceled_name,
                              "onUpdate:modelValue": _cache[16] || (_cache[16] = ($event: any) => ((_ctx.customer.hosting_canceled_name) = $event))
                            }, null, 8, ["data", "disabled", "required", "transform", "modelValue"]),
                            _createVNode(_component_bp_button, {
                              class: "flex-0",
                              icon: "plus",
                              color: "green",
                              onClick: _cache[17] || (_cache[17] = ($event: any) => (_ctx.contactWindow = 'hosting')),
                              large: ""
                            }),
                            _createVNode(_component_bp_window, {
                              "model-value": !!_ctx.contactWindow,
                              "onUpdate:modelValue": _cache[18] || (_cache[18] = ($event: any) => (_ctx.contactWindow = ''))
                            }, {
                              header: _withCtx(() => [
                                _createVNode(_component_translate, { tag: "h2" }, {
                                  default: _withCtx(() => _cache[28] || (_cache[28] = [
                                    _createTextVNode("New contact")
                                  ])),
                                  _: 1
                                })
                              ]),
                              footer: _withCtx(() => [
                                _createVNode(_component_bp_form_button, {
                                  type: "save",
                                  errors: _ctx.contactEditor?.errors,
                                  action: _ctx.saveContact
                                }, null, 8, ["errors", "action"]),
                                _createVNode(_component_bp_form_button, {
                                  type: "reset",
                                  action: _ctx.contactEditor?.reset
                                }, null, 8, ["action"]),
                                _createVNode(_component_bp_form_button, {
                                  type: "cancel",
                                  action: () => _ctx.contactWindow = ''
                                }, null, 8, ["action"])
                              ]),
                              default: _withCtx(() => [
                                _createVNode(_component_bp_contact_editor, {
                                  ref: "contactEditor",
                                  unwrap: "",
                                  redirect: false,
                                  "customer-id": _ctx.customer._id
                                }, null, 8, ["customer-id"])
                              ]),
                              _: 1
                            }, 8, ["model-value"])
                          ]),
                          _: 1
                        }, 8, ["label", "required"]))
                      : _createCommentVNode("", true),
                    (_ctx.show('hosting') && _ctx.show('hostingCancel') && _ctx.show('hosting_canceled_date'))
                      ? (_openBlock(), _createBlock(_component_bp_input, {
                          key: 1,
                          disabled: !_ctx.selected,
                          indent: 3.5,
                          label: _ctx.$gettext('Canceled date'),
                          required: _ctx.customer.hostingCancel,
                          onChangeValid: _cache[19] || (_cache[19] = ($event: any) => (_ctx.setError('hosting_canceled_date', _ctx.$gettext('Canceled date'), $event))),
                          type: "date",
                          modelValue: _ctx.customer.hosting_canceled_date,
                          "onUpdate:modelValue": _cache[20] || (_cache[20] = ($event: any) => ((_ctx.customer.hosting_canceled_date) = $event))
                        }, null, 8, ["disabled", "label", "required", "modelValue"]))
                      : _createCommentVNode("", true),
                    (_ctx.show('hosting') && _ctx.show('hostingCancel') && _ctx.show('hosting_canceled_type'))
                      ? (_openBlock(), _createBlock(_component_bp_select, {
                          key: 2,
                          data: _ctx.cancelledTypeStore.cancelledTypes,
                          disabled: !_ctx.selected,
                          indent: 3.5,
                          label: _ctx.$gettext('Canceled type'),
                          required: _ctx.customer.hostingCancel,
                          onChangeValid: _cache[21] || (_cache[21] = ($event: any) => (_ctx.setError('hosting_canceled_type', _ctx.$gettext('Canceled type'), $event))),
                          "search-property": "name",
                          modelValue: _ctx.customer.hosting_canceled_type,
                          "onUpdate:modelValue": _cache[22] || (_cache[22] = ($event: any) => ((_ctx.customer.hosting_canceled_type) = $event))
                        }, null, 8, ["data", "disabled", "label", "required", "modelValue"]))
                      : _createCommentVNode("", true),
                    (_ctx.show('hosting') && _ctx.show('hostingCancel') && _ctx.show('hosting_canceledTo'))
                      ? (_openBlock(), _createBlock(_component_bp_input, {
                          key: 3,
                          disabled: !_ctx.selected,
                          indent: 3.5,
                          label: _ctx.$gettext('Canceled to'),
                          required: _ctx.customer.hostingCancel,
                          onChangeValid: _cache[23] || (_cache[23] = ($event: any) => (_ctx.setError('hosting_canceledTo', _ctx.$gettext('Canceled to'), $event))),
                          type: "date",
                          modelValue: _ctx.customer.hosting_canceledTo,
                          "onUpdate:modelValue": _cache[24] || (_cache[24] = ($event: any) => ((_ctx.customer.hosting_canceledTo) = $event))
                        }, null, 8, ["disabled", "label", "required", "modelValue"]))
                      : _createCommentVNode("", true)
                  ]),
                  _: 1
                }, 8, ["collapsed"]))
              : _createCommentVNode("", true)
          ]),
          _: 1
        }, 8, ["collapsed"]))
      : _createCommentVNode("", true),
    (_ctx.components.length > 0 && _ctx.show('products.bestproxy.installedVersion'))
      ? (_openBlock(), _createBlock(_component_translate, {
          key: 9,
          tag: `h${_ctx.headingLevel}`
        }, {
          default: _withCtx(() => _cache[29] || (_cache[29] = [
            _createTextVNode("Additional information")
          ])),
          _: 1
        }, 8, ["tag"]))
      : _createCommentVNode("", true),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.components, (component) => {
      return (_openBlock(), _createElementBlock(_Fragment, {
        key: component._id + '_' + _ctx.getVersionNumbers(component).length
      }, [
        (_ctx.show('products.bestproxy.installedVersion'))
          ? (_openBlock(), _createBlock(_component_bp_select, {
              key: 0,
              data: _ctx.versionNumbers.get(component._id),
              disabled: !_ctx.selected,
              indent: 1.25,
              label: _ctx.$ngettext('%{component} version', '%{component} versions', _ctx.getVersionNumbers(component).length, { component: _ctx.versionStore.getName(component) }),
              text: _ctx.$ngettext('The last known version the customer has installed', 'The last known versions the customer has installed', _ctx.getVersionNumbers(component).length),
              "model-value": _ctx.getVersionNumbers(component),
              transform: _ctx.transformVersionNumber,
              "onUpdate:modelValue": ($event: any) => (_ctx.updateVersionNumbers(component, $event)),
              clearable: "",
              multiple: "",
              "sort-order": "desc",
              "sort-property": "date"
            }, _createSlots({ _: 2 }, [
              (_ctx.getVersionNumbersMeta(component).size > 0)
                ? {
                    name: "text",
                    fn: _withCtx(() => [
                      _createElementVNode("div", _hoisted_3, [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.getVersionNumbersMeta(component).entries(), ([key, meta]) => {
                          return (_openBlock(), _createElementBlock("span", {
                            key: key,
                            class: "flex-column gap-sm"
                          }, [
                            (_openBlock(), _createBlock(_resolveDynamicComponent(`h${_ctx.headingLevel + 1}`), null, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(_ctx.formatTransformedVersionNumber(key)), 1)
                              ]),
                              _: 2
                            }, 1024)),
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(Object.entries(meta), ([metaKey, metaValue]) => {
                              return (_openBlock(), _createElementBlock("p", { key: metaKey }, [
                                _createElementVNode("strong", null, _toDisplayString(_ctx.versionStore.getTranslatedMetaKey(metaKey)) + ":", 1),
                                _createTextVNode(" " + _toDisplayString(metaValue), 1)
                              ]))
                            }), 128))
                          ]))
                        }), 128))
                      ])
                    ]),
                    key: "0"
                  }
                : undefined
            ]), 1032, ["data", "disabled", "label", "text", "model-value", "transform", "onUpdate:modelValue"]))
          : _createCommentVNode("", true)
      ], 64))
    }), 128))
  ]))
}