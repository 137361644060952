import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, Fragment as _Fragment, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, createSlots as _createSlots, withModifiers as _withModifiers, resolveDynamicComponent as _resolveDynamicComponent } from "vue"

const _hoisted_1 = {
  key: 1,
  class: "flex gap-x-md gap-y-sm",
  style: {"align-items":"baseline","flex-wrap":"wrap"}
}
const _hoisted_2 = {
  key: 0,
  style: {"font-size":"smaller"}
}
const _hoisted_3 = { class: "mono" }
const _hoisted_4 = { key: 7 }
const _hoisted_5 = { key: 0 }
const _hoisted_6 = { class: "flex-column" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_translate = _resolveComponent("translate")!
  const _component_bp_input = _resolveComponent("bp-input")!
  const _component_bp_select = _resolveComponent("bp-select")!
  const _component_bp_label = _resolveComponent("bp-label")!
  const _component_bp_icon = _resolveComponent("bp-icon")!
  const _component_bp_customer_editor_product_bestinformed = _resolveComponent("bp-customer-editor-product-bestinformed")!
  const _component_bp_fieldset = _resolveComponent("bp-fieldset")!
  const _component_bp_customer_editor_product_bestproxy = _resolveComponent("bp-customer-editor-product-bestproxy")!
  const _component_bp_customer_editor_product_bestzero = _resolveComponent("bp-customer-editor-product-bestzero")!
  const _component_bp_card = _resolveComponent("bp-card")!
  const _component_bp_form_button = _resolveComponent("bp-form-button")!

  return (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.unwrap ? 'span' : 'bp-card'), {
    class: _normalizeClass(['bp-customer-editor', { 'bp-customer-editor--compact': _ctx.compactMode }]),
    loading: _ctx.loading || _ctx.store.isLoading(),
    "fill-height": "",
    "fill-width": ""
  }, {
    header: _withCtx(() => [
      (!_ctx.isEdit)
        ? (_openBlock(), _createBlock(_component_translate, {
            key: 0,
            tag: "h2"
          }, {
            default: _withCtx(() => _cache[32] || (_cache[32] = [
              _createTextVNode("New customer")
            ])),
            _: 1
          }))
        : (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _createVNode(_component_translate, {
              tag: "h2",
              "translate-params": { customer: _ctx.customer.company }
            }, {
              default: _withCtx(() => _cache[33] || (_cache[33] = [
                _createTextVNode("Edit customer \"%{customer}\"")
              ])),
              _: 1
            }, 8, ["translate-params"]),
            (!!_ctx.modelValue?.customerNumber)
              ? (_openBlock(), _createElementBlock("em", _hoisted_2, [
                  _cache[35] || (_cache[35] = _createTextVNode("(")),
                  _createVNode(_component_translate, null, {
                    default: _withCtx(() => _cache[34] || (_cache[34] = [
                      _createTextVNode("Customer number")
                    ])),
                    _: 1
                  }),
                  _cache[36] || (_cache[36] = _createTextVNode(": ")),
                  _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.customer.customerNumber), 1),
                  _cache[37] || (_cache[37] = _createTextVNode(")"))
                ]))
              : _createCommentVNode("", true)
          ]))
    ]),
    footer: _withCtx(() => [
      _createVNode(_component_bp_form_button, {
        type: "save",
        errors: _ctx.errors,
        action: _ctx.save
      }, null, 8, ["errors", "action"]),
      _createVNode(_component_bp_form_button, {
        type: "reset",
        action: _ctx.reset
      }, null, 8, ["action"]),
      _createVNode(_component_bp_form_button, {
        type: "cancel",
        to: { name: 'admin.customer.overview' }
      })
    ]),
    default: _withCtx(() => [
      (_ctx.customer)
        ? (_openBlock(), _createElementBlock("form", {
            key: 0,
            class: "flex-column gap-md",
            onSubmit: _cache[31] || (_cache[31] = _withModifiers(() => {}, ["prevent"]))
          }, [
            (_ctx.show('company'))
              ? (_openBlock(), _createBlock(_component_bp_input, {
                  key: 0,
                  error: _ctx.companyExists() ? _ctx.$gettext('This company does already exist.') : undefined,
                  label: _ctx.$gettext('Company name'),
                  onChangeValid: _cache[0] || (_cache[0] = ($event: any) => (_ctx.setError('company', _ctx.$gettext('Company name'), $event))),
                  required: "",
                  maxlength: 256,
                  modelValue: _ctx.customer.company,
                  "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.customer.company) = $event))
                }, null, 8, ["error", "label", "modelValue"]))
              : _createCommentVNode("", true),
            (_ctx.show('dcpc'))
              ? (_openBlock(), _createBlock(_component_bp_input, {
                  key: 1,
                  label: _ctx.$gettext('Data processing contract'),
                  text: _ctx.$gettext('This customer already signed the data processing contract'),
                  onChangeValid: _cache[2] || (_cache[2] = ($event: any) => (_ctx.setError('dcpc', _ctx.$gettext('Data processing contract'), $event))),
                  flush: "",
                  type: "checkbox",
                  modelValue: _ctx.customer.dcpc,
                  "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.customer.dcpc) = $event))
                }, null, 8, ["label", "text", "modelValue"]))
              : _createCommentVNode("", true),
            (_ctx.show('pilot'))
              ? (_openBlock(), _createBlock(_component_bp_input, {
                  key: 2,
                  label: _ctx.$gettext('Pilot'),
                  text: _ctx.$gettext('This customer is a pilot customer'),
                  onChangeValid: _cache[4] || (_cache[4] = ($event: any) => (_ctx.setError('pilot', _ctx.$gettext('Pilot'), $event))),
                  flush: "",
                  type: "checkbox",
                  modelValue: _ctx.customer.pilot,
                  "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.customer.pilot) = $event))
                }, null, 8, ["label", "text", "modelValue"]))
              : _createCommentVNode("", true),
            (_ctx.show('reseller'))
              ? (_openBlock(), _createBlock(_component_bp_input, {
                  key: 3,
                  label: _ctx.$gettext('Is reseller'),
                  text: _ctx.$gettext('This customer is a reseller'),
                  onChangeValid: _cache[6] || (_cache[6] = ($event: any) => (_ctx.setError('reseller', _ctx.$gettext('Is reseller'), $event))),
                  flush: "",
                  type: "checkbox",
                  modelValue: _ctx.customer.reseller,
                  "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.customer.reseller) = $event))
                }, null, 8, ["label", "text", "modelValue"]))
              : _createCommentVNode("", true),
            (_ctx.show('commentfield'))
              ? (_openBlock(), _createBlock(_component_bp_input, {
                  key: 4,
                  label: _ctx.$gettext('Notes'),
                  onChangeValid: _cache[8] || (_cache[8] = ($event: any) => (_ctx.setError('commentfield', _ctx.$gettext('Notes'), $event))),
                  type: "textarea",
                  maxlength: 500,
                  modelValue: _ctx.customer.commentfield,
                  "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.customer.commentfield) = $event))
                }, null, 8, ["label", "modelValue"]))
              : _createCommentVNode("", true),
            (_ctx.show('accountid'))
              ? (_openBlock(), _createBlock(_component_bp_input, {
                  key: 5,
                  label: _ctx.$gettext('VTiger ID'),
                  type: "number",
                  modelValue: _ctx.customer.accountid,
                  "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.customer.accountid) = $event))
                }, null, 8, ["label", "modelValue"]))
              : _createCommentVNode("", true),
            (_ctx.show('containsReseller'))
              ? (_openBlock(), _createBlock(_component_bp_select, {
                  key: 6,
                  data: _ctx.store.getReseller(),
                  label: _ctx.$gettext('Reseller'),
                  "search-property": "company",
                  modelValue: _ctx.customer.containsReseller,
                  "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => ((_ctx.customer.containsReseller) = $event))
                }, null, 8, ["data", "label", "modelValue"]))
              : _createCommentVNode("", true),
            (_ctx.show(['company', 'dcpc', 'pilot', 'reseller', 'commentfield', 'accountid', 'containsReseller']) && _ctx.show(['street1', 'street2', 'street3', 'zip_code', 'city', 'country']))
              ? (_openBlock(), _createElementBlock("hr", _hoisted_4))
              : _createCommentVNode("", true),
            (_ctx.show(['street1', 'street2', 'street3', 'zip_code', 'city', 'country']))
              ? (_openBlock(), _createBlock(_component_translate, {
                  key: 8,
                  tag: "h3"
                }, {
                  default: _withCtx(() => _cache[38] || (_cache[38] = [
                    _createTextVNode("Address information")
                  ])),
                  _: 1
                }))
              : _createCommentVNode("", true),
            (_ctx.show(['street1', 'street2', 'street3']))
              ? (_openBlock(), _createBlock(_component_bp_label, {
                  key: 9,
                  label: _ctx.$gettext('Address')
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_bp_input, {
                      "model-value": _ctx.customerAddress,
                      pattern: /^.*(\n.*){0,2}(?!(.|\n)+)/,
                      resizable: false,
                      rows: 3,
                      onChangeValid: _cache[12] || (_cache[12] = ($event: any) => (_ctx.setError('address', _ctx.$gettext('Address'), $event))),
                      "onUpdate:modelValue": _ctx.updateAddress,
                      maxlength: 100,
                      type: "textarea"
                    }, null, 8, ["model-value", "onUpdate:modelValue"])
                  ]),
                  _: 1
                }, 8, ["label"]))
              : _createCommentVNode("", true),
            (_ctx.show(['zip_code', 'city']))
              ? (_openBlock(), _createBlock(_component_bp_label, {
                  key: 10,
                  label: _ctx.$gettext('ZIP code') + ' / ' + _ctx.$gettext('City')
                }, {
                  default: _withCtx(() => [
                    (_ctx.show('zip_code'))
                      ? (_openBlock(), _createBlock(_component_bp_input, {
                          key: 0,
                          onChangeValid: _cache[13] || (_cache[13] = ($event: any) => (_ctx.setError('zip_code', _ctx.$gettext('ZIP code'), $event))),
                          modelValue: _ctx.customer.zip_code,
                          "onUpdate:modelValue": _cache[14] || (_cache[14] = ($event: any) => ((_ctx.customer.zip_code) = $event)),
                          pattern: "^(?:[A-Za-z0-9]|[-\\s](?![-\\s])){1,10}$"
                        }, null, 8, ["modelValue"]))
                      : _createCommentVNode("", true),
                    (_ctx.show('city'))
                      ? (_openBlock(), _createBlock(_component_bp_input, {
                          key: 1,
                          onChangeValid: _cache[15] || (_cache[15] = ($event: any) => (_ctx.setError('city', _ctx.$gettext('City'), $event))),
                          class: "flex-2",
                          modelValue: _ctx.customer.city,
                          "onUpdate:modelValue": _cache[16] || (_cache[16] = ($event: any) => ((_ctx.customer.city) = $event)),
                          maxlength: 100
                        }, null, 8, ["modelValue"]))
                      : _createCommentVNode("", true)
                  ]),
                  _: 1
                }, 8, ["label"]))
              : _createCommentVNode("", true),
            (_ctx.show('country'))
              ? (_openBlock(), _createBlock(_component_bp_select, {
                  key: 11,
                  data: _ctx.countryStore.countries,
                  label: _ctx.$gettext('Country'),
                  onChangeValid: _cache[17] || (_cache[17] = ($event: any) => (_ctx.setError('country', _ctx.$gettext('Country'), $event))),
                  clearable: "",
                  "close-on-click": "",
                  "search-property": "name",
                  modelValue: _ctx.customer.country,
                  "onUpdate:modelValue": _cache[18] || (_cache[18] = ($event: any) => ((_ctx.customer.country) = $event))
                }, null, 8, ["data", "label", "modelValue"]))
              : _createCommentVNode("", true),
            (_ctx.show([
        'products',
        // BESTINFORMED
        'editionProduct', 'editionApps', 'apps', 'apps_full', 'numberOfLicences', 'unlimited_cals', 'mobilecals', 'clusternodes', 'subscription', 'supportFrom', 'supportTo', 'supportCancel',
        'canceled_name', 'canceled_date', 'canceled_type', 'canceledTo', 'hosting', 'hostingruntimeto', 'hostingCancel', 'hosting_canceled_name', 'hosting_canceled_date', 'hosting_canceled_type',
        'hosting_canceledTo',
        // BESTPROXY
        'bestproxycores', 'bestproxyram', 'bestproxyruntimeto', 'webaccess', 'bestproxywebaccess',
      ]))
              ? (_openBlock(), _createElementBlock(_Fragment, { key: 12 }, [
                  (_ctx.unwrap)
                    ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                        (_ctx.show(['street1', 'street2', 'street3', 'zip_code', 'city', 'country']) && _ctx.show([
            'products.bestinformed',
            // BESTINFORMED
            'editionProduct', 'editionApps', 'apps', 'apps_full', 'numberOfLicences', 'unlimited_cals', 'mobilecals', 'clusternodes', 'subscription', 'supportFrom', 'supportTo', 'supportCancel',
            'canceled_name', 'canceled_date', 'canceled_type', 'canceledTo', 'hosting', 'hostingruntimeto', 'hostingCancel', 'hosting_canceled_name', 'hosting_canceled_date', 'hosting_canceled_type',
            'hosting_canceledTo',
            // BESTPROXY
            'bestproxycores', 'bestproxyram', 'bestproxyruntimeto', 'webaccess', 'bestproxywebaccess',
          ]))
                          ? (_openBlock(), _createElementBlock("hr", _hoisted_5))
                          : _createCommentVNode("", true),
                        (_ctx.show([
            'products.bestinformed',
            // BESTINFORMED
            'editionProduct', 'editionApps', 'apps', 'apps_full', 'numberOfLicences', 'unlimited_cals', 'mobilecals', 'clusternodes', 'subscription', 'supportFrom', 'supportTo', 'supportCancel',
            'canceled_name', 'canceled_date', 'canceled_type', 'canceledTo', 'hosting', 'hostingruntimeto', 'hostingCancel', 'hosting_canceled_name', 'hosting_canceled_date', 'hosting_canceled_type',
            'hosting_canceledTo',
            // BESTPROXY
            'bestproxycores', 'bestproxyram', 'bestproxyruntimeto', 'webaccess', 'bestproxywebaccess',
          ]))
                          ? (_openBlock(), _createBlock(_component_translate, {
                              key: 1,
                              tag: "h3"
                            }, {
                              default: _withCtx(() => _cache[39] || (_cache[39] = [
                                _createTextVNode("Product information")
                              ])),
                              _: 1
                            }))
                          : _createCommentVNode("", true),
                        (_ctx.store.hasProduct(_ctx.customer, 'bestinformed') && _ctx.show([
            'products.bestinformed',
            // BESTINFORMED
            'editionProduct', 'editionApps', 'apps', 'apps_full', 'numberOfLicences', 'unlimited_cals', 'mobilecals', 'clusternodes', 'subscription', 'supportFrom', 'supportTo', 'supportCancel',
            'canceled_name', 'canceled_date', 'canceled_type', 'canceledTo', 'hosting', 'hostingruntimeto', 'hostingCancel', 'hosting_canceled_name', 'hosting_canceled_date', 'hosting_canceled_type',
            'hosting_canceledTo',
          ]))
                          ? (_openBlock(), _createBlock(_component_bp_fieldset, {
                              key: 2,
                              "intense-border": ""
                            }, {
                              legend: _withCtx(() => [
                                _createVNode(_component_bp_icon, {
                                  icon: _ctx.versionStore.getIcon('bestinformed')
                                }, null, 8, ["icon"]),
                                _cache[40] || (_cache[40] = _createElementVNode("h3", null, [
                                  _createTextVNode("bestinformed"),
                                  _createElementVNode("sup", null, "®")
                                ], -1))
                              ]),
                              default: _withCtx(() => [
                                _createVNode(_component_bp_customer_editor_product_bestinformed, {
                                  errors: _ctx.errors,
                                  excluded: _ctx.excluded,
                                  "heading-level": 4,
                                  included: _ctx.included,
                                  loading: _ctx.loading,
                                  "model-value": _ctx.customer,
                                  unwrap: _ctx.unwrap,
                                  "ignore-vtiger": _ctx.ignoreVtiger,
                                  "onUpdate:modelValue": _ctx.updateCustomer,
                                  selected: ""
                                }, null, 8, ["errors", "excluded", "included", "loading", "model-value", "unwrap", "ignore-vtiger", "onUpdate:modelValue"])
                              ]),
                              _: 1
                            }))
                          : _createCommentVNode("", true),
                        (_ctx.store.hasProduct(_ctx.customer, 'bestproxy') && _ctx.show([
            'products.bestproxy',
            // BESTPROXY
            'bestproxycores', 'bestproxyram', 'bestproxyruntimeto', 'webaccess', 'bestproxywebaccess',
          ]))
                          ? (_openBlock(), _createBlock(_component_bp_fieldset, {
                              key: 3,
                              "intense-border": ""
                            }, {
                              legend: _withCtx(() => [
                                _createVNode(_component_bp_icon, {
                                  icon: _ctx.versionStore.getIcon('bestproxy')
                                }, null, 8, ["icon"]),
                                _cache[41] || (_cache[41] = _createElementVNode("h3", null, "bestproxy", -1))
                              ]),
                              default: _withCtx(() => [
                                _createVNode(_component_bp_customer_editor_product_bestproxy, {
                                  errors: _ctx.errors,
                                  excluded: _ctx.excluded,
                                  "heading-level": 4,
                                  included: _ctx.included,
                                  loading: _ctx.loading,
                                  "model-value": _ctx.customer,
                                  unwrap: _ctx.unwrap,
                                  "onUpdate:modelValue": _ctx.updateCustomer,
                                  selected: ""
                                }, null, 8, ["errors", "excluded", "included", "loading", "model-value", "unwrap", "onUpdate:modelValue"])
                              ]),
                              _: 1
                            }))
                          : _createCommentVNode("", true),
                        (_ctx.store.hasProduct(_ctx.customer, 'bestzero') && _ctx.show([
            'products.bestzero',
            // BESTZERO
            'numberOfLicences', 'supportTo'
          ]))
                          ? (_openBlock(), _createBlock(_component_bp_fieldset, {
                              key: 4,
                              "intense-border": ""
                            }, {
                              legend: _withCtx(() => [
                                _createVNode(_component_bp_icon, {
                                  icon: _ctx.versionStore.getIcon('bestzero')
                                }, null, 8, ["icon"]),
                                _cache[42] || (_cache[42] = _createElementVNode("h3", null, [
                                  _createTextVNode("bestzero"),
                                  _createElementVNode("sup", null, "®")
                                ], -1))
                              ]),
                              default: _withCtx(() => [
                                _createVNode(_component_bp_customer_editor_product_bestzero, {
                                  errors: _ctx.errors,
                                  excluded: _ctx.excluded,
                                  "heading-level": 4,
                                  included: _ctx.included,
                                  loading: _ctx.loading,
                                  "model-value": _ctx.customer,
                                  unwrap: _ctx.unwrap,
                                  "onUpdate:modelValue": _ctx.updateCustomer,
                                  selected: ""
                                }, null, 8, ["errors", "excluded", "included", "loading", "model-value", "unwrap", "onUpdate:modelValue"])
                              ]),
                              _: 1
                            }))
                          : _createCommentVNode("", true)
                      ], 64))
                    : (_openBlock(), _createBlock(_component_bp_card, {
                        key: 1,
                        "fill-width": "",
                        "active-tab": _ctx.activeTab,
                        tabs: _ctx.productTabs,
                        "tabs-position": "inside",
                        unwrap: ""
                      }, _createSlots({ _: 2 }, [
                        (_ctx.show([
            'products.bestinformed',
            // BESTINFORMED
            'editionProduct', 'editionApps', 'apps', 'apps_full', 'numberOfLicences', 'unlimited_cals', 'mobilecals', 'clusternodes', 'subscription', 'supportFrom', 'supportTo', 'supportCancel',
            'canceled_name', 'canceled_date', 'canceled_type', 'canceledTo', 'hosting', 'hostingruntimeto', 'hostingCancel', 'hosting_canceled_name', 'hosting_canceled_date', 'hosting_canceled_type',
            'hosting_canceledTo',
            // BESTPROXY
            'bestproxycores', 'bestproxyram', 'bestproxyruntimeto', 'webaccess', 'bestproxywebaccess',
          ]))
                          ? {
                              name: "tabs-header",
                              fn: _withCtx(() => [
                                _createVNode(_component_translate, { tag: "h3" }, {
                                  default: _withCtx(() => _cache[43] || (_cache[43] = [
                                    _createTextVNode("Product information")
                                  ])),
                                  _: 1
                                })
                              ]),
                              key: "0"
                            }
                          : undefined,
                        (_ctx.show([
            // BESTINFORMED
            'products.bestinformed',
            'editionProduct', 'editionApps', 'apps', 'apps_full', 'numberOfLicences', 'unlimited_cals', 'mobilecals', 'clusternodes', 'subscription', 'supportFrom', 'supportTo', 'supportCancel',
            'canceled_name', 'canceled_date', 'canceled_type', 'canceledTo', 'hosting', 'hostingruntimeto', 'hostingCancel', 'hosting_canceled_name', 'hosting_canceled_date', 'hosting_canceled_type',
            'hosting_canceledTo',
            // BESTPROXY
            'products.bestproxy',
            'bestproxycores', 'bestproxyram', 'bestproxyruntimeto', 'webaccess', 'bestproxywebaccess', 'hosting', 'hostingruntimeto', 'hostingCancel', 'hosting_canceled_name', 'hosting_canceled_date',
            'hosting_canceled_type', 'hosting_canceledTo',
            // BESTZERO
            'products.bestzero',
          ]))
                          ? {
                              name: "tabs",
                              fn: _withCtx(() => [
                                _createElementVNode("div", _hoisted_6, [
                                  _createElementVNode("span", {
                                    class: _normalizeClass(['bp-customer-editor__tab', { 'bp-customer-editor__tab--active': _ctx.activeTab === 'bestinformed' }]),
                                    onClick: _cache[20] || (_cache[20] = ($event: any) => (_ctx.activeTab = 'bestinformed'))
                                  }, [
                                    _createVNode(_component_bp_input, {
                                      "model-value": _ctx.store.hasProduct(_ctx.customer, 'bestinformed'),
                                      "onUpdate:modelValue": _cache[19] || (_cache[19] = ($event: any) => (_ctx.updateProductselection('bestinformed'))),
                                      class: "bp-customer-editor__tab-checkbox",
                                      type: "checkbox"
                                    }, null, 8, ["model-value"]),
                                    _createVNode(_component_bp_icon, {
                                      icon: _ctx.versionStore.getIcon('bestinformed')
                                    }, null, 8, ["icon"]),
                                    _cache[44] || (_cache[44] = _createElementVNode("span", null, [
                                      _createTextVNode("bestinformed"),
                                      _createElementVNode("sup", null, "®")
                                    ], -1))
                                  ], 2),
                                  _createElementVNode("span", {
                                    class: _normalizeClass(['bp-customer-editor__tab', { 'bp-customer-editor__tab--active': _ctx.activeTab === 'bestproxy' }]),
                                    onClick: _cache[22] || (_cache[22] = ($event: any) => (_ctx.activeTab = 'bestproxy'))
                                  }, [
                                    _createVNode(_component_bp_input, {
                                      "model-value": _ctx.store.hasProduct(_ctx.customer, 'bestproxy'),
                                      "onUpdate:modelValue": _cache[21] || (_cache[21] = ($event: any) => (_ctx.updateProductselection('bestproxy'))),
                                      class: "bp-customer-editor__tab-checkbox",
                                      type: "checkbox"
                                    }, null, 8, ["model-value"]),
                                    _createVNode(_component_bp_icon, {
                                      icon: _ctx.versionStore.getIcon('bestproxy')
                                    }, null, 8, ["icon"]),
                                    _cache[45] || (_cache[45] = _createElementVNode("span", null, "bestproxy", -1))
                                  ], 2),
                                  _createVNode(_component_translate, {
                                    class: "bp-customer-editor__tab-separator",
                                    style: _normalizeStyle({ '--tab-separator-text-width': `${_ctx.$gettext('OR').length / 2}ch` })
                                  }, {
                                    default: _withCtx(() => _cache[46] || (_cache[46] = [
                                      _createTextVNode("OR")
                                    ])),
                                    _: 1
                                  }, 8, ["style"]),
                                  _createElementVNode("span", {
                                    class: _normalizeClass(['bp-customer-editor__tab', { 'bp-customer-editor__tab--active': _ctx.activeTab === 'bestzero' }]),
                                    onClick: _cache[24] || (_cache[24] = ($event: any) => (_ctx.activeTab = 'bestzero'))
                                  }, [
                                    _createVNode(_component_bp_input, {
                                      "model-value": _ctx.store.hasProduct(_ctx.customer, 'bestzero'),
                                      "onUpdate:modelValue": _cache[23] || (_cache[23] = ($event: any) => (_ctx.updateProductselection('bestzero'))),
                                      class: "bp-customer-editor__tab-checkbox",
                                      type: "checkbox"
                                    }, null, 8, ["model-value"]),
                                    _createVNode(_component_bp_icon, {
                                      icon: _ctx.versionStore.getIcon('bestzero')
                                    }, null, 8, ["icon"]),
                                    _cache[47] || (_cache[47] = _createElementVNode("span", null, [
                                      _createTextVNode("bestzero"),
                                      _createElementVNode("sup", null, "®")
                                    ], -1))
                                  ], 2)
                                ])
                              ]),
                              key: "1"
                            }
                          : undefined,
                        (_ctx.show([
            'products.bestinformed',
            // BESTINFORMED
            'editionProduct', 'editionApps', 'apps', 'apps_full', 'numberOfLicences', 'unlimited_cals', 'mobilecals', 'clusternodes', 'subscription', 'supportFrom', 'supportTo', 'supportCancel',
            'canceled_name', 'canceled_date', 'canceled_type', 'canceledTo', 'hosting', 'hostingruntimeto', 'hostingCancel', 'hosting_canceled_name', 'hosting_canceled_date', 'hosting_canceled_type',
            'hosting_canceledTo',
          ]))
                          ? {
                              name: "tab-bestinformed",
                              fn: _withCtx(() => [
                                _createVNode(_component_bp_input, {
                                  "model-value": _ctx.customer.productselection?.includes('bestinformed'),
                                  "onUpdate:modelValue": _cache[25] || (_cache[25] = ($event: any) => (_ctx.updateProductselection('bestinformed'))),
                                  class: "bp-customer-editor__tab-checkbox",
                                  type: "checkbox"
                                }, null, 8, ["model-value"]),
                                _cache[48] || (_cache[48] = _createElementVNode("span", null, [
                                  _createTextVNode("bestinformed"),
                                  _createElementVNode("sup", null, "®")
                                ], -1))
                              ]),
                              key: "2"
                            }
                          : undefined,
                        (_ctx.show([
            'products.bestinformed',
            // BESTINFORMED
            'editionProduct', 'editionApps', 'apps', 'apps_full', 'numberOfLicences', 'unlimited_cals', 'mobilecals', 'clusternodes', 'subscription', 'supportFrom', 'supportTo', 'supportCancel',
            'canceled_name', 'canceled_date', 'canceled_type', 'canceledTo', 'hosting', 'hostingruntimeto', 'hostingCancel', 'hosting_canceled_name', 'hosting_canceled_date', 'hosting_canceled_type',
            'hosting_canceledTo',
          ]))
                          ? {
                              name: "tab-content-bestinformed",
                              fn: _withCtx(() => [
                                _createVNode(_component_bp_customer_editor_product_bestinformed, {
                                  modelValue: _ctx.customer,
                                  "onUpdate:modelValue": [
                                    _cache[26] || (_cache[26] = ($event: any) => ((_ctx.customer) = $event)),
                                    _ctx.updateCustomer
                                  ],
                                  selected: !!_ctx.store.hasProduct(_ctx.customer, 'bestinformed'),
                                  included: _ctx.included,
                                  excluded: _ctx.excluded,
                                  loading: _ctx.loading,
                                  unwrap: _ctx.unwrap,
                                  errors: _ctx.errors,
                                  vtigerProps: ['editionProduct', 'editionApps', 'numberOfLicences', 'unlimited_cals', 'mobileCals', 'clusternodes', 'appPlus', 'additionalApps', 'supportTo', 'subscription'],
                                  "ignore-vtiger": _ctx.ignoreVtiger
                                }, null, 8, ["modelValue", "selected", "included", "excluded", "loading", "unwrap", "errors", "ignore-vtiger", "onUpdate:modelValue"])
                              ]),
                              key: "3"
                            }
                          : undefined,
                        (_ctx.show([
            'products.bestproxy',
            // BESTPROXY
            'bestproxycores', 'bestproxyram', 'bestproxyruntimeto', 'webaccess', 'bestproxywebaccess', 'hosting', 'hostingruntimeto', 'hostingCancel', 'hosting_canceled_name', 'hosting_canceled_date',
            'hosting_canceled_type', 'hosting_canceledTo',
          ]))
                          ? {
                              name: "tab-bestproxy",
                              fn: _withCtx(() => [
                                _createVNode(_component_bp_input, {
                                  "model-value": _ctx.customer.productselection?.includes('bestproxy'),
                                  "onUpdate:modelValue": _cache[27] || (_cache[27] = ($event: any) => (_ctx.updateProductselection('bestproxy'))),
                                  class: "bp-customer-editor__tab-checkbox",
                                  type: "checkbox"
                                }, null, 8, ["model-value"]),
                                _cache[49] || (_cache[49] = _createElementVNode("span", null, "bestproxy", -1))
                              ]),
                              key: "4"
                            }
                          : undefined,
                        (_ctx.show([
            'products.bestproxy',
            // BESTPROXY
            'bestproxycores', 'bestproxyram', 'bestproxyruntimeto', 'webaccess', 'bestproxywebaccess', 'hosting', 'hostingruntimeto', 'hostingCancel', 'hosting_canceled_name', 'hosting_canceled_date',
            'hosting_canceled_type', 'hosting_canceledTo',
          ]))
                          ? {
                              name: "tab-content-bestproxy",
                              fn: _withCtx(() => [
                                _createVNode(_component_bp_customer_editor_product_bestproxy, {
                                  modelValue: _ctx.customer,
                                  "onUpdate:modelValue": [
                                    _cache[28] || (_cache[28] = ($event: any) => ((_ctx.customer) = $event)),
                                    _ctx.updateCustomer
                                  ],
                                  selected: !!_ctx.store.hasProduct(_ctx.customer, 'bestproxy'),
                                  included: _ctx.included,
                                  excluded: _ctx.excluded,
                                  loading: _ctx.loading,
                                  unwrap: _ctx.unwrap,
                                  errors: _ctx.errors
                                }, null, 8, ["modelValue", "selected", "included", "excluded", "loading", "unwrap", "errors", "onUpdate:modelValue"])
                              ]),
                              key: "5"
                            }
                          : undefined,
                        (_ctx.show([
            'products.bestzero',
            // BESTZERO
            'numberOfLicences', 'supportTo',
          ]))
                          ? {
                              name: "tab-bestzero",
                              fn: _withCtx(() => [
                                _createVNode(_component_bp_input, {
                                  "model-value": _ctx.customer.productselection?.includes('bestzero'),
                                  "onUpdate:modelValue": _cache[29] || (_cache[29] = ($event: any) => (_ctx.updateProductselection('bestzero'))),
                                  class: "bp-customer-editor__tab-checkbox",
                                  type: "checkbox"
                                }, null, 8, ["model-value"]),
                                _cache[50] || (_cache[50] = _createElementVNode("span", null, [
                                  _createTextVNode("bestzero"),
                                  _createElementVNode("sup", null, "®")
                                ], -1))
                              ]),
                              key: "6"
                            }
                          : undefined,
                        (_ctx.show([
            'products.bestzero',
            // BESTZERO
            'numberOfLicences', 'supportTo',
          ]))
                          ? {
                              name: "tab-content-bestzero",
                              fn: _withCtx(() => [
                                _createVNode(_component_bp_customer_editor_product_bestzero, {
                                  modelValue: _ctx.customer,
                                  "onUpdate:modelValue": [
                                    _cache[30] || (_cache[30] = ($event: any) => ((_ctx.customer) = $event)),
                                    _ctx.updateCustomer
                                  ],
                                  selected: !!_ctx.store.hasProduct(_ctx.customer, 'bestzero'),
                                  included: _ctx.included,
                                  excluded: _ctx.excluded,
                                  loading: _ctx.loading,
                                  unwrap: _ctx.unwrap,
                                  errors: _ctx.errors
                                }, null, 8, ["modelValue", "selected", "included", "excluded", "loading", "unwrap", "errors", "onUpdate:modelValue"])
                              ]),
                              key: "7"
                            }
                          : undefined
                      ]), 1032, ["active-tab", "tabs"]))
                ], 64))
              : _createCommentVNode("", true)
          ], 32))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["class", "loading"]))
}